.pl-input-box {
  .mydp {
    border: 0;
    .selector {
      z-index: 102;
    }

    .selection {
      color: #262b32;
      font-weight: 300;
      font-size: 16px;
    }
  }
}

.demand-datepicker {
  bottom: -182px;
  position: absolute;
  right: 107px;
  width: 9%;
  .mydp {
    border: 0;
    .selectiongroup {
      display: none;
    }
  }
}
