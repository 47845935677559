@import 'colors';
@import 'variables';

%vertical-border {
  align-self: stretch;

  &:not(:last-of-type) {
    background-image: linear-gradient($color-table-border 33%, transparent 0%);
    background-position: right;
    background-repeat: repeat-y;
    background-size: 1px 8px;
  }
}

mat-table {
  .mat-mdc-header-row {
    .mat-mdc-header-cell {
      @extend %vertical-border;

      color: $color-table-text;
      font-size: 16px;
      font-weight: 300;
      padding-left: $unit;
      padding-right: $unit;

      .mat-mdc-sort-header-stem {
        display: none;
      }

      $indicator-thickness: 1.2px;

      .mat-mdc-sort-header-pointer-left,
      .mat-mdc-sort-header-pointer-right {
        height: $indicator-thickness;
      }

      .mat-mdc-sort-header-pointer-middle {
        height: $indicator-thickness;
        width: $indicator-thickness;
      }
    }
  }

  .mat-mdc-footer-row {
    .mat-mdc-footer-cell {
      @extend %vertical-border;
    }
  }

  .mat-mdc-row {
    border-bottom-width: 0;

    &:nth-of-type(2n) {
      background-color: $color-table-even-row;
    }

    mat-form-field {
      width: 90%;
    }

    .mat-mdc-cell {
      @extend %vertical-border;

      align-items: flex-start;
      font-size: 16px;
      font-weight: 300;
      line-height: 175%;
      padding-bottom: $unit / 3;
      padding-left: $unit;
      padding-right: $unit;
      padding-top: $unit / 3;
    }

    .material-icons {
      color: $color-boulder;
    }
  }
}
