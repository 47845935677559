@import 'variables';
@import 'colors';

ng-dropdown-panel.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option.ng-option-selected {
      color: $select-list-item-fg;
    }
  }
}
