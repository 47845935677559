@import 'statuses-colors';

mat-chip {
    &.mat-mdc-chip {
        border-radius: 6px;
        font-weight: 300;
        min-height: 25px;
        padding-bottom: 3px;
        padding-top: 3px;
    }

  &.margin-right {
    margin-right: 10px;

  }
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    &.approved {
        background-color: $color-status-green-transparent;
      }
    &.pending {
        background-color: $color-status-blue-transparent;
      }
    &.declined {
        background-color: $color-status-red-transparent;
      }
}
