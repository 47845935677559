mat-paginator {
    mat-form-field {
        &.mat-mdc-form-field-appearance .mat-mdc-form-field-wrapper {
            padding-bottom: 1.25em;
        }
        
        &.mat-mdc-form-field-appearance .mat-mdc-form-field-infix {
            padding: .4375em 0;
        }
    }

    .mat-mdc-paginator-page-size-label,
    .mat-mdc-paginator-range-label {
        font-weight: 500;
    }
}
