.cdk-overlay-pane {

  &.fullscreen {
    border-radius: 0;
    height: 100vh;
    margin: 0;
    max-height: none;
    overflow: hidden;
    top: 0;
    width: 100%;
  }
}

// fix for issue Modal opening moves body up
// https://github.com/angular/components/issues/7390
.cdk-global-scrollblock {
  overflow: hidden;
  position: initial;
}