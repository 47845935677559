@import "variables";

$tab-height: 40px;
$tab-bg: #dcdee2;
$tab-border-color: #bbb;
$tab-font-color: #343434;
$tab-font-color-new: #686868;

.tabs {
  $btn-shift: 165px;
  $tab-number: 100;
  background: none;
  display: flex;
  height: $tab-height;
  list-style: none;
  margin: 0 auto;
  padding: 0 0 0 35px;
  position: relative;

  text-align: left;

  @for $i from 1 through $tab-number {
    .tab:nth-child(#{$i}) {

      z-index: #{$tab-number - $i};
    }
  }

  .tab.active {
    z-index: 101;
  }

  .tab {
    color: $tab-font-color;
    cursor: pointer;
    display: flex;
    flex: 1;
    font-weight: 300;
    font-size: 16px;
    line-height: #{$tab-height};
    height: $tab-height;
    letter-spacing: -0.2px; // this is one of examples when we could use Roboto Condensed font, but not sure yet.
    margin-left: -35px;
    outline: none;
    position: relative;
    text-align: center;
    text-decoration: none;

    &.active {
      color: $color-black;
      font-size: 18px;
      font-weight: normal;

      path {
        fill: $color-background-grey;
      }

      .svg-border {
        display: none;
      }

      .tab-title {
        background: $color-background-grey;
        color: $color-black;
      }
    }

    .tab-title {
      background: $tab-bg;
      border-radius: 1px 1px 0 0;
      flex: 1;
      overflow: hidden;
      padding: 0 10px;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    svg {
      height: $tab-height;
    }

    path {
      fill: $tab-bg;
    }

    path.svg-border {
      fill: $tab-border-color;
    }
  }

  .svg-left, .svg-right {
    height: $tab-height;
    width: 47px;
  }

  .svg-left {
    margin-right: -1px;
  }

  .svg-right {
    margin-left: -1px;
  }

  .tab.prev-btn, .tab.next-btn {
    opacity: 0.4;
    position: absolute;
    top: 0;
    transition: opacity 300ms ease-in-out;
    width: 200px;
    z-index: 0;
    &:hover {
      opacity: 0.6;
    }
  }
  width: calc(100% - 200px);
  .prev-btn {
    left: 0;
    margin-left: 0;
    transform: translateX(-$btn-shift);
  }

  .next-btn {
    right: 0;
    transform: translateX($btn-shift);
  }
}

.tab-content {
  min-height: calc(100vh - 101px); // fixme: temp
}
