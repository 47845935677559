mat-form-field {
    &.full-width {
        width: 100%;
    }

    &.space-bottom {
      padding-bottom: 20px;
    }

    &.mat-mdc-form-field-appearance .mat-mdc-form-field-underline,
    .mat-mdc-form-field-underline {
        background-color: rgba(0, 0, 0, .1);
    }

    &.mat-mdc-form-field-appearance .mat-mdc-form-field-wrapper {
        padding-bottom: 0;
    }

    .mat-mdc-form-field-underline {
        display: none;
    }

    &.underline {
      .mat-mdc-form-field-underline {
        display: block;
        background-color: rgba(0, 0, 0, .1);
      }
    }

    &.mat-mdc-form-field-appearance .mat-mdc-form-field-infix {
        padding-bottom: 2px;
    }
}

.mat-mdc-form-field .mat-mdc-icon-button .mat-mdc-icon {
  font-size: 18px;
}

// Manipulate background color on hover and focus
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}
