@import 'colors';

.conversation-container .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}

.conversation-container {
  font-size: 12px;
  font-weight: 400;
  min-width: 300px;

  .header {
    display: flex;
    margin-top: 20px;

    .tab {
      background-color: $color-mercury;
      border-radius: 12px 12px 0 0;
      font-size: 13px;
      font-weight: 300;
      width: 50%;
      text-align: center;
      height: 20px;
      padding: 4px;
      line-height: 13px;
      cursor: pointer;

      &.has-unread {
        color: red;
      }
    }

    .tab.active {
      background-color: $color-silver;
      font-weight: 500;
    }
  }

  .no-items {
    opacity: 0.5;
    text-align: center;
  }

  .wrapper {
    height: 190px;
    line-height: 16px;
    overflow-y: auto;
    padding: 5px;
  }

  .comment {
    width: 80%;
    background-color: $color-background-grey;
    border-radius: 10px;
    float: left;
    margin-bottom: 10px;
    padding: 10px;
    position: relative;
    line-break: anywhere;

    .comment-header {
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
    }

    &.unread {
      animation-name: colorAnimation;
      animation-duration: 4000ms;
    }
  }

  .comment.self {
    float: right;
  }

  .confirm-delete-box {
    position: absolute;
    top: 5px;
    right: 10px;
    text-align: right;
    display: flex;

    div {
      line-height: 20px;
    }
  }

  .actions-box {
    position: absolute;
    padding-top: 5px;
    opacity: 0;
    left: 0;
    right: 10px;
    top: 0;
    bottom: 0;
    text-align: right;

    mat-icon:hover {
      color: $color-wish-menu-blue;
    }

    mat-icon.delete:hover {
      color: $color-wish-menu-red;
    }
  }

  .actions-box:hover {
    opacity: 1;
  }

  .edit {
    ::ng-deep .mat-mdc-form-field-flex {
      padding-top: 0;
      padding-bottom: 0;
    }

    mat-form-field {
      width: 100%;

      input {
        width: 80%;
      }
    }
  }

  .edit-actions-box {
    position: absolute;
    padding-top: 18px;
    right: 20px;
    top: 0;
    bottom: 0;
    text-align: right;
  }

  mat-icon {
    color: $color-alto;
    cursor: pointer;
    font-size: 20px;
  }

  mat-form-field.mat-mdc-focused mat-icon.send {
    color: $grey-grey-900;
  }

  mat-icon.cancel:hover {
    color: $color-wish-menu-red;
  }

  mat-icon.accept:hover {
    color: $basic-green-color;
  }

  .note {
    padding: 5px;
    border-bottom: 2px solid $color-silver;
    position: relative;
    line-break: anywhere;

    .note-header {
      font-size: 10px;
      font-weight: 500;
      padding-bottom: 5px;
      text-align: left;
    }

    .note-header.self {
      color: $color-wish-menu-blue;
    }

    .edit-actions-box {
      padding-top: 14px;
    }

    &.unread {
      animation-name: colorAnimation;
      animation-duration: 4000ms;
    }
  }

  .write-box {
    margin-top: 5px;

    mat-form-field {
      width: 100%;
    }

    mat-icon {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

@keyframes colorAnimation {
  0% {
    color: red;
  }
  100% {
    color: inherit;
  }
}
