.icon {
  display: block;
  mask-size: cover;
  background-color: black;

  &.arrow-down {
    mask-image: url('/assets/images/arrow_down.svg');
  }

  &.arrow-left {
    mask-image: url('/assets/images/arrow_left.svg');
  }

  &.arrow-right {
    mask-image: url('/assets/images/arrow_right.svg');
  }

  &.arrow-up {
    mask-image: url('/assets/images/arrow_up.svg');
  }

  &.bot {
    mask-image: url('/assets/images/bot.svg');
  }

  &.calc {
    mask-image: url('/assets/images/calc.svg');
  }

  &.calendar {
    mask-image: url('/assets/images/calendar.svg');
  }

  &.calendar-locked {
    mask-image: url('/assets/images/calendar_locked.svg');
  }

  &.comment {
    mask-image: url('/assets/images/comment.svg');
  }

  &.chat {
    mask-image: url('/assets/images/chat.svg');
  }

  &.contrails {
    mask-image: url('/assets/images/contrails.svg');
  }

  &.diagram-bars {
    mask-image: url('/assets/images/diagram_bars.svg');
  }

  &.download {
    mask-image: url('/assets/images/download.svg');
  }

  &.feedback {
    mask-image: url('/assets/images/feedback.svg');
  }

  &.filter {
    mask-image: url('/assets/images/filter.svg');
  }

  &.information {
    mask-image: url('/assets/images/information.svg');
  }

  &.list-view {
    mask-image: url('/assets/images/list_view.svg');
  }

  &.lock {
    mask-image: url('/assets/images/lock.svg');
  }

  &.passport {
    mask-image: url('/assets/images/passport.svg');
  }

  &.passport-locked {
    mask-image: url('/assets/images/passport_locked.svg');
  }

  &.pause {
    mask-image: url('/assets/images/pause.svg');
  }

  &.plane {
    mask-image: url('/assets/images/plane.svg');
  }

  &.play {
    mask-image: url('/assets/images/play.svg');
  }

  &.questions {
    mask-image: url('/assets/images/questions.svg');
  }

  &.remove-circle {
    mask-image: url('/assets/images/remove_circle.svg');
  }

  &.reorder {
    mask-image: url('/assets/images/reorder.svg');
  }

  &.send {
    mask-image: url('/assets/images/send.svg');
  }

  &.signing {
    mask-image: url('/assets/images/signing.svg');
  }

  &.sort {
    mask-image: url('/assets/images/sort.svg');
  }

  &.sort-arrows {
    mask-image: url('/assets/images/sort_arrows.svg');
  }

  &.sort-arrows-filled {
    mask-image: url('/assets/images/sort_arrows_filled.svg');
  }

  &.table-clear {
    mask-image: url('/assets/images/table_clear.svg');
  }

  &.unlock {
    mask-image: url('/assets/images/unlock.svg');
  }

  &.update {
    mask-image: url('/assets/images/update.svg');
  }

  &.update-settings {
    mask-image: url('/assets/images/update_settings.svg');
  }

  &.warning-triangle {
    mask-image: url('/assets/images/warning_triangle.svg');
  }

  &.upload {
    mask-image: url('/assets/images/upload.svg');
  }

  &.upload-large {
    mask-image: url('/assets/images/upload_large.svg');
  }

  &.document-approved {
    mask-image: url('/assets/images/document_approved.svg');
  }

  &.document-for-signing {
    mask-image: url('/assets/images/document_for_signing.svg');
  }

  &.editable-file {
    mask-image: url('/assets/images/editable_file.svg');
  }

  &.list-edit {
    mask-image: url('/assets/images/list_edit.svg');
  }
}
