@import 'variables';

// local variables
$border-radius-val: 6px;

.widget {
  background: $color-white;
  border-radius: $border-radius-val;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.28);
  overflow: hidden;
  position: relative;

  &.hidden-shadow {
    box-shadow: none;
  }

  .header {
    border-radius: $border-radius-val $border-radius-val 0 0;
    display: flex;
    flex-direction: row;
    height: $widget-header-height;
    line-height: $widget-header-height;
    text-align: center;
    &.minimized p {
      border-radius: $border-radius-val 0 0 $border-radius-val;
    }

    p {
      border-radius: $border-radius-val 0 0 0;
      flex: 1;
      font-weight: normal;
      margin: 0;
      padding: 0;
    }

    .single-menu {
      position: relative;
      width: 44px;
    }

    .multi-menu {
      position: relative;
      width: 88px;
    }

    a {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: $widget-header-height;
      justify-content: center;
      position: absolute;
      width: 100%;
      z-index: 4;
    }

    i {
      color: $color-icons-gray;
    }

    .circle {
      background: $color-white;
      border-radius: 50%;
      height: 30px;
      transition: background 0.4s ease-out;
      width: 30px;
      &.idle:hover {
        background: $color-wget-idle-input-border;
      }
    }
  }

  .header-text {
    background: darken($color-timeline-category-bg, 5%);
    border-radius: $border-radius-val 0 0;
    display: block;
    font-size: 18px;
    font-weight: 300;
    height: 100%;
  }

  .burger-btn {
    display: block;
    padding-left: 1px;
    padding-top: 3px;
    i {
      background: $color-wget-edit-bg;
      display: block;
      height: 3px;
      margin: 0 auto 4px;
      position: relative;
      top: 0;
      transition: all 300ms linear;
      width: 20px;
    }

    &.active i {
      &:first-child {
        top: 7px;
        transform: rotate(45deg);
      }

      &:not(:first-child):not(:last-child) {
        opacity: 0;
        width: 0;
      }

      &:last-child {
        top: -7px;
        transform: rotate(-45deg);
      }
    }
  }

  .menu-btn i {
    color: $color-wget-edit-bg;
  }

  .widget-content {
    border-radius: 0 0 6px 6px;
    padding-right: 44px;

    &:not(.editable) {
      background: $color-white;
    }

    &:not(.minimized) {
      min-height: 155px;
    }
  }

  .underlined {
    color: $color-wget-edit-bg;
    font-size: 25px;
    font-weight: 300;
    position: relative;
    &:before {
      background: $color-wget-edit-bg;
      bottom: -0.07rem;
      content: '';
      height: 1px;
      position: absolute;
      transition: all 0.3s ease-out;
      width: 0;
    }
  }

  .circle {
    align-items: center;
    background: $color-wget-edit-bg;
    color: $color-white;
    display: flex;
    height: 40px;
    justify-content: center;
    position: relative;
    transition: 0.3s linear;
    width: 40px;
    i {
      font-size: 1.7rem;
      margin-top: 1px;
      position: relative;
      z-index: 4;
    }
  }
}

.colorized .widget {
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
  .header {
    border: 1px solid $color-white;
    border-bottom: 0;
  }

  &.editable {
    box-shadow: 0 2px 0 0 $color-white;
    .header {
      border-color: #ebebec;
    }

    .header-text {
      background: $color-wget-edit-bg;
      color: $color-white;
    }

    .multi-menu {
      background: rgb(221, 225, 229);
      border-radius: 0 $border-radius-val 0 0;
      .material-icons {
        color: $color-wget-edit-bg;
      }

      .circle:first-child {
        margin-right: 10px;
      }
    }

    .widget-content {
      background: #ebebec;
      padding-bottom: 10px;
    }
  }
}

.sticky .widget {
  overflow: visible;

  .header {
    position: sticky;
    top: 0;
    z-index: 100;
  }
}
