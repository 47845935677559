@import 'variables';

%h {
    color: $color-header-fg;
    font-weight: 500;
}

h1 {
    @extend %h;
    font-size: 2.3333333rem;
    padding: 50px;
}

h2 {
    @extend %h;
    font-size: 1.6rem;
    margin: 20px 0;
}

h3 {
    @extend %h;
    font-size: 1.07rem;
    margin: 20px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    &.center {
        text-align: center;
    }

    &.light {
        color: $color-header-light-fg;
    }
}
