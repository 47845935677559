@import 'colors';

app-error-page {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    text-align: center;

    .error-container {
        margin: 1em auto;
    }

    img.plane {
        margin-left: 5px; //sass-lint:disable-line property-units
        margin-top: 18.4px; //sass-lint:disable-line property-units
        vertical-align: top;
    }

    h1,
    p,
    a,
    i {
        color: $color-white;
        margin-left: 1em;
        margin-right: 1em;
    }

    p {
        font-size: 1.333333rem;
        font-weight: 300;
        line-height: 130%;
        margin-bottom: 1.1em;
    }

    a {
        border-bottom: 1px solid transparent;
        text-decoration: none;
        transition: border-bottom-color 0.5s;

        &:hover {
            border-bottom: 1px solid $color-white;
        }
    }
}
