@import 'colors';

.mat-calendar-content {
  margin-left: 30px;
}

mat-month-view {
  .mat-calendar-body-cell {
    &.selected-date {
      background-color: $grey-grey-200;
      border-radius: 100%;
      color: $color-white;

      div {
        color: inherit;
      }
    }
    &.start-of-week::after {
      font-size: 10px;
      font-weight: normal;
      height: unset;
      left: -20px;
      opacity: 0.38;
      pointer-events: none;
      position: absolute;
      top: unset;
      width: unset;
      background: none;
      color: black;
    }


    @for $n from 1 to 53 {
      &.week-#{$n}::after {
        content: '#{$n}';
      }
    }
  }
}
