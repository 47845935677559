@import 'variables';

/** Components **/

$border-radius-base: 4px !default;
$border-radius-large: 6px !default;

/** MODALS **/

$modal-inner-padding: 15px !default;

//** Padding applied to the modal title
$modal-title-padding: 15px !default;
//** Modal title line-height

//** Background color of modal content area
$modal-content-bg: #fff !default;
//** Modal content border color
$modal-content-border-color: rgba(0, 0, 0, 0.2) !default;
//** Modal content border color **for IE8**
$modal-content-fallback-border-color: #999 !default;

//** Modal backdrop background color
$modal-backdrop-bg: #000 !default;
//** Modal backdrop opacity
$modal-backdrop-opacity: 0.5 !default;
//** Modal header border color
$modal-header-border-color: #e5e5e5 !default;
//** Modal footer border color
$modal-background-color: #fff !default;

$modal-lg: 900px !default;
$modal-md: 600px !default;
$modal-sm: 300px !default;
$modal-error-popup: 550px !default;
$modal-width: 997px;
$modal-v-margin: 10%;
$top-bar-height: 50px;

$zindex-modal-background: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-top-bar-tooltip: 1000;

.modal {
  background-color: $modal-background-color;
  border-radius: 6px;
  display: none;
  left: 0;
  margin: auto;
  max-height: 70%;
  outline: none;
  overflow-y: auto;
  padding: 0;
  position: fixed;
  right: 0;
  top: -100% + $modal-v-margin;
  transform: translateY(0);
  transition: opacity $modalTransitionTime ease-in, transform $modalTransitionTime ease-in;
  z-index: 1050;

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: 1;
  }

  &.in {
    opacity: 1;
    .modal-dialog {
      opacity: 1;
    }
  }

  &.simple {
    top: 50px;
    transition: opacity $modalTransitionTime ease-in;
  }

  &.tall {
    max-height: 100% - 2 * $modal-v-margin;
    transform: translateY(100vh);
    transition-delay: $modalTransitionTime;

    .modal-dialog {
      transition-delay: $modalTransitionTime * 2.5;
    }
  }

  &.confirm {
    transition-delay: 150ms;
    top: 50vh;
    transform: translateY(-50%);
    width: 480px;
    background-color: $color-white;
    box-shadow: 0 0 200px 40px rgba(#222, 0.25), 0 20px 60px 0 rgba(#222, 0.4);
    transition: opacity $modalTransitionTime ease-in;
  }

  &.alert {
    transition-delay: 150ms;
    top: 50vh;
    transform: translateY(-50%);
    background-color: $color-white;
    box-shadow: 0 0 200px 40px rgba(#222, 0.25), 0 20px 60px 0 rgba(#222, 0.4);
    transition: opacity $modalTransitionTime ease-in;
    width: 50%;
  }

  &.request {
    max-width: 1000px;
  }
}

modal-overlay:not(.tall) .modal.custom-alert {
  max-width: 90vw;
  width: $modal-error-popup;
}

css-backdrop {
  &.hide {
    opacity: 0 !important;
  }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  transition: opacity $modalTransitionTime ease-in;
  &:not(.alert):not(.confirm) .modal-header {
    font-weight: 300;
    font-size: 24px;
    line-height: 2;
    background: $color-alto;
    margin: -10px -10px 30px;
    text-align: center;
  }

  &.confirm,
  &.alert {
    margin: 0;
    .modal-header {
      position: relative;
      font-size: 16px;
      font-weight: 300;
      padding-top: 66px;
      padding-left: 150px;
      .material-icons {
        position: absolute;
        left: 35px;
        top: 50px;
        font-size: 80px;
        color: $color-black;
      }
    }

    .modal-body {
      padding-left: 130px;
      font-size: 24px;
    }
  }

  &.confirm,
  &.request,
  &.alert {
    .modal-footer {
      display: flex;
      margin-top: 60px;
      > button {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        border: 0;
        color: $color-white;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 2px;
        }
      }

      .material-icons {
        font-size: 31px;
        margin: 0 15px;
      }

      .ok {
        background: #00e01a;

        &:disabled {
          background-color: #cdcdcd;
          cursor: default;
        }
      }

      .cancel {
        background: #f6a623;
      }

      .back {
        background: #9b9b9b;
      }
    }
  }

  &.request {
    position: relative;
    margin: 0;
    z-index: 2;
    .modal-content:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: $color-white;
      z-index: 1;
    }

    .modal-footer {
      overflow: hidden;
      border-radius: 0 0 6px 6px;

      &.agenda {
        border-radius: 0;
      }

      > button {
        border: solid 1px $btn-border-color;
        color: $active-text-color;
      }

      .ok {
        background-color: $btn-submit-color;
        border-color: $btn-border-color;
        color: $btn-submit-text-color;

        &:disabled {
          border-color: $btn-color-disabled;
          background-color: $btn-color-disabled;
          color: $btn-submit-text-color;
          background-image: none;
        }
      }

      .cancel {
        border-color: $btn-border-color;
        background-color: $btn-cancel-color;
      }

      .delete {
        color: $btn-delete-color;
        border: none;

        &:disabled {
          color: $btn-color-disabled;
          background-image: none;
        }
      }

      i {
        vertical-align: middle;
      }
    }
  }
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-background;
  background-color: $modal-backdrop-bg;
  // Fade for backdrop
  transition: opacity 300ms ease-in;
  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: 1;
  }

  &.in {
    opacity: $modal-backdrop-opacity;
  }

  &.confirm,
  &.alert {
    opacity: 0;
  }
}

.allow-top-bar {
  top: $top-bar-height !important;
  z-index: $zindex-top-bar-tooltip - 1 !important;
}

.error-modal {
  z-index: 9999 !important;
}
