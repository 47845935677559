@import 'colors';
@import 'mixins';
@import 'placeholders';
@import 'functions';
@import 'fonts';

$nav-height: 99px;
$nav-height-small: $nav-height - 40px;
$color-box-size: 31px;
$modalTransitionTime: 250ms;

// ------------------ FORMS -------------
$basic-size: 20px;
$rounded-border-radius: 100px;

// ------------------ report -------------
$splitter-bottom: 20px;
$edit-btn-top: 32px;
$edit-btn-border-width: 4px;
$btn-radius: 15px;
$edit-btn-size: ($btn-radius + $edit-btn-border-width) * 2;

// mat-table
$arrow-size: 20px;

// -----------------  breakpoints ----------
$vacay-sm-breakpoint: 969px;

$unit: 15px;
$border-radius: 6px;

// ----------widget---------
$widget-header-height: 44px;
