@import "variables";

.chart {
  .plugin-header,
  .right-container .header,
  .left-container .header,
  .chips-header {
    line {
      stroke: #e1e1e1;
      stroke-width: 1;
    }
  }

  .plugin-header,
  .right-container .header,
  .left-container .header,
  .sectors,
  .day-label,
  .chips-header {
    text {
      fill: $color-text-dark-blue;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      text-anchor: middle;
    }
  }

  .pagination-btn {
    circle {
      fill: $color-background-grey-lighter;
      stroke: #d0d0d0;
    }

    text {
      text-anchor: middle;
    }
  }

  .tick {
    line {
      stroke: #979797;
      stroke-width: 1;
    }

    text {
      color: $color-text-dark-blue;
      font-weight: 300;
      font-size: 12px;
      text-anchor: end;
      transform: rotate(-60deg);
    }
  }

  .left-container {
    .header text {
      transform: rotate(270deg);
    }

    .date {
      text-anchor: start;
    }

    .sectors text {
      font-weight: 300;
      font-size: 15px;
      text-anchor: end;
    }
  }

  .right-container {
    cursor: pointer;
    .date-label {
      rect {
        fill: #eee;
      }

      line {
        stroke: $color-text-dark-blue;
      }

      text {
        fill: $color-black;
        font-weight: 300;
        font-size: 12px;
        text-anchor: middle;
      }
    }

    .pagination-btn text {
      font-size: 12px;
    }

    .pagination-header {
      rect {
        fill: $color-white;
        stroke: #d0d0d0;
      }

      text {
        fill: $color-black;
        text-anchor: middle;
        tspan {
          font-weight: 300;
          font-size: 12px;
          &:first-child {
            font-weight: normal;
          }
        }
      }
    }

    .day-label {
      cursor: pointer;

      rect {
        fill: $color-background-grey-lighter;
      }

      text {
        fill: $color-black;
      }

      &.selected {
        rect {
          fill: #5087a2;
        }

        text {
          fill: $color-white;
        }
      }

      &.disabled {
        cursor: default;
      }
    }
  }

  .ticks {
    //g:nth-child(even) .tick text {
    //  font-weight: 500;
    //}

    .tick text.bold {
      font-weight: 500;
    }
  }

  .shift {
    &.demand {
      text-anchor: middle;
      text {
        fill: #1e304f;
        font-size: 16px;
      }
    }
  }

  .chart-chip {
    * {
      text-anchor: start;
    }

    .material-icons {
      font-size: 13px;
    }

    .chart-chip-title {
      font-weight: normal;
      font-size: 12px;
    }
  }

  //================== shifts-chart ===============

  &.shifts-chart {
    .shift {
      text {
        fill: $color-white;
        font-weight: normal;
        font-size: 16px;
        text-anchor: middle;
      }

      &.count text {
        fill: $color-black;
      }
    }

    text.day {
      fill: $color-black;
      font-size: 15px;
    }

    text.date {
      fill: $color-black;
      font-family: $font-condensed-roboto;
      font-weight: 300;
      font-size: 15px;
    }

    .right-container {
      text {
        font-weight: 300;
      }

      text.day,
      text.stats-overall {
        font-family: $font-condensed-roboto;
        font-weight: normal;
      }
    }
  }

  //================== clusters-chart ===============

  &.clusters-chart {
    $btn-font-color: #767676;
    $btn-shadow-disabled: rgba(0, 0, 0, 0.13);
    $btn-shadow-enabled: rgba(0, 0, 0, 0.17);
    $color-success: #008000;
    $color-warning: #ffdb16;

    &.editable .left-container g.content:not(.drag) g:hover {
      cursor: ns-resize;
      rect.background {
        fill: $color-warning;
        opacity: 1;
      }
    }

    &.editable {
      margin-top: 25px;
    }

    .draggable rect.background {
      fill: $color-warning;
    }

    .cluster-label {
      cursor: default;

      path {
        fill: #d9dde3;
      }

      text {
        fill: $color-text-dark-blue;
        font-size: 12px;
        text-anchor: start;
      }

      &.active {
        cursor: pointer;

        path {
          fill: #5087a2;
        }

        text {
          fill: $color-white;
        }
      }
    }

    .date-label {
      .background {
        opacity: 0;
        &.highlight {
          fill: $color-success !important;
          opacity: 1;
        }
      }

      .date,
      .day {
        fill: $color-text-dark-blue;
        font-weight: 300;
        font-size: 10px;
      }

      .day {
        font-size: 14px;
        text-anchor: end;
      }
    }

    .holiday {
      rect {
        fill: #ffdede;
      }

      path {
        fill: $color-status-red-tiny;
      }
    }
  }

  //================== demand-chart ===============

  &.demand-chart {
    .demand {
      tspan {
        color: #1e304f;
        font-size: 16px;
        text-anchor: middle;
      }

      .lo {
        fill: #5087a2;
      }
    }

    &.editable {
      .demand rect {
        fill: $color-white !important;
      }
    }

    .cluster-btn-icon {
      display: none;
      font-family: $font-mat-icons;
      &.active {
        display: inline-block;
      }
    }
  }

  //================== heatmap-chart ===============

  &.heatmap-chart {
    .heat text {
      font-family: $font-condensed-roboto;
      font-weight: 300;
      font-size: 16px;
      letter-spacing: -1px;
      text-anchor: middle;
    }
  }

  //================== line-chart ===============
  &.line-chart {
    .right-container .date-label text {
      text-anchor: start;
    }
  }

  //================== shiftr-chart ===============
  &.shiftr-chart {
    $color-success: #008000;
    $color-warning: #ffdb16;
    .left-container .sectors text {
      text-anchor: start;
    }

    .total text {
      fill: #1e304f;
      font-weight: normal;
    }

    .total rect {
      fill: #ebedf1;
    }

    .stat text {
      fill: #272b32;
      font-weight: 300;
    }

    .stat rect {
      fill: #f9f9f9;
    }

    .stat .error rect {
      fill: #f00 !important;
    }

    .stat .error text {
      fill: $color-white !important;
    }

    .highlight-hover {
      opacity: 0;
    }

    &.editable {
      .left-container g.sectors:not(.drag) g:hover {
        cursor: ns-resize;
        rect.highlight-hover {
          fill: $color-warning !important;
          opacity: 1 !important;
        }
      }

      .highlight-guess {
        fill: $color-success !important;
        opacity: 1;
      }

      .total rect {
        fill: rgba($color-white, 0.5);
      }

      .stat rect {
        fill: rgba($color-background-grey, 0.5);
      }

      .shift.error rect {
        stroke: $color-status-red-tiny;
      }

      .left-container g.sectors {
        g {
          rect.background {
            opacity: 0;
          }
        }
      }

      .draggable {
        rect.background {
          fill: none;
        }

        cursor: grabbing;
      }

      .add-button {
        opacity: 1;
        circle {
          fill: $color-background-grey-lighter;
          stroke: #d0d0d0;
        }

        text {
          font-size: 21px;
          text-anchor: middle;
        }
      }

      .footer {
        fill: $color-white;
      }
    }

    .add-button {
      opacity: 0;
    }

    .footer {
      fill: #d8dde3;
    }
  }
}
