@import 'colors';

@mixin clearfix {
  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

@mixin hatch-bg {
  background-color: transparent;
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 4px, #d9d9d9 4px, #d9d9d9 5px);
}

@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin chips {
  $cross-size: 14px;
  border: 0;
  border-radius: 17px;
  color: #272b32;
  display: inline-block;
  font-weight: 300;
  font-size: 16px;
  margin: 3px 5px 3px 0;
  overflow: hidden;
  padding: 3px 30px 3px 10px;
  position: relative;
  white-space: normal;

  span.deselect-option {
    color: $color-black;
    cursor: pointer;
    font-size: $cross-size;
    height: $cross-size;
    line-height: $cross-size;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@mixin text-error {
  font-weight: bold;
  color: $color-error;
}

@mixin text-success {
  color: $color-success;
}
