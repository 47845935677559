@import 'variables';

// NOTE. Do not intend to delete this file when you delete old ROSTr code.
// because some classes related to base timeline for Agenda and some for BREAKr !!!

.base-timeline {
  $task-fills: #00e04a #ffd300 #ff1400;

  svg {
    overflow: visible !important;

    line {
      shape-rendering: crispEdges; // fix for render width line
      vector-effect: non-scaling-stroke;
    }

    text {
      user-select: none;
    }
  }

  // INDICATION

  .indication {
    path {
      fill: #00baff;
      opacity: 0.65;
    }

    text {
      fill: rgba(0, 0, 0, 0.5);
      font-size: 11px;
      font-weight: normal;
      text-anchor: middle;

      &.material-icons {
        fill: #fff7f7;
        font-size: 14px;
        text-anchor: start;
      }
    }
  }

  // HEADERS

  .tabs-header {
    .tab {
      text {
        fill: #343434;
        font-weight: 100;
        font-size: 18px;
        letter-spacing: -0.2px;
        text-anchor: middle;
      }

      cursor: pointer;

      &.active {
        cursor: default;
        text {
          fill: $color-black;
          font-weight: 300;
        }
      }
    }
  }

  .time-header {
    &.hide {
      display: none;
    }

    &.floating {
      .time-header-background {
        opacity: 0.9;
      }

      .time-header-intervals {
        .line {
          stroke: $color-background-grey;
        }

        .week-indication {
          transform: translate(0, 5px);

          circle {
            fill: $color-background-grey;
          }
        }
      }
    }

    .time-header-background {
      fill: $color-white;
      opacity: 0;
    }
  }

  .schedules-header {
    .schedules-header-background {
      rect {
        fill: #cdd0d6;
      }

      .schedules-header-background-path {
        fill: $color-white;
      }

      .schedules-header-background-shadow {
        fill: #bcbcbd;
      }
    }

    .schedules-header-title {
      font-family: $font-condensed-roboto;
      font-size: 15px;
      font-weight: 300;
      text-anchor: end;
    }

    .schedules-header-rows .schedule {
      &.hide {
        display: none;
      }

      .schedule-background {
        stroke: $color-white;
        stroke-width: 1px;
        //opacity: .65;
      }

      .schedule-indication {
        stroke: $color-white;
        stroke-width: 1px;
      }

      .schedule-title {
        font-family: $font-condensed-roboto;
        font-size: 12px;
        font-weight: 300;
        text-anchor: start;
      }
    }
  }

  .options-header {
    .options-header-background {
      fill: #cdd0d6;
    }

    .options-header-title {
      font-family: $font-condensed-roboto;
      font-size: 15px;
      font-weight: 300;
      text-anchor: start;
    }

    .options-header-order-groups {
      cursor: pointer;

      circle {
        stroke: #838383;
      }
    }

    .options-header-sort-employees {
      cursor: pointer;

      circle {
        fill: #808080;
      }

      text {
        fill: $color-white;
        font-size: 12px;
        font-weight: 600;
        text-anchor: middle;
      }
    }

    .options-header-order-employees {
      cursor: pointer;

      circle {
        fill: $color-white;

        &.shadow {
          fill: $color-black;
          transform: translate(0, 1px);
        }
      }

      text {
        font-size: 16px;
        text-anchor: middle;
      }
    }

    .options-header-buttons {
      .button {
        &.active {
          .button-background {
            fill: #a1a0a0;
            stroke: #e0e0e0;
            stroke-width: 1.5px;
          }

          .button-shadow {
            visibility: hidden;
          }

          .button-title,
          .button-icon {
            fill: $color-white;
          }
        }

        .button-background {
          fill: $color-white;
        }

        .button-shadow {
          fill: rgba(0, 0, 0, 0.17);
          transform: translate(0, 1px);
        }

        .button-title {
          font-size: 14px;
          font-weight: 300;
          text-anchor: middle;
        }

        .button-icon {
          font-size: 17px;
        }
      }
    }
  }

  $task-types: 'success' 'warning' 'danger';
  position: relative;

  .main-bg,
  .main-corner {
    fill: $color-background-grey;
  }

  .line {
    pointer-events: none;
    stroke: rgba($color-timeline-border, 0.5);
    stroke-width: 1;
    &.wide {
      stroke-width: 2;
    }

    &.thin {
      stroke-dasharray: 4 2;
    }

    &.line-interval {
      stroke: $color-white;
    }

    &.dark {
      stroke: $color-background-grey;
    }
  }

  .text,
  .grid-header {
    fill: $color-timeline-text-header;
    font-family: $font-condensed-roboto;
    font-size: 17px;
    letter-spacing: 0.1px;
    text-anchor: middle;
    &.small {
      font-size: 12px;
    }

    &.hour,
    &.hour-half,
    &.date-day {
      font-size: 13px;
      font-weight: 300;
    }
  }

  .interval-header {
    circle {
      fill: transparent;
    }

    .week-indication {
      circle {
        fill: $color-white;
        &.dark {
          fill: $color-background-grey;
        }
      }

      text {
        font-size: 11px;
      }
    }
  }

  .hour {
    font-weight: normal;
  }

  .hour-half {
    fill: $color-timeline-text-header;
    font-weight: 300;
    opacity: 0.3;
  }

  .day-title {
    font-family: $font-condensed-roboto;
    font-size: 20px;
  }

  .menu-item,
  .category {
    .line {
      stroke: rgba($color-timeline-border, 0.5);
      stroke-width: 1;
      &.root {
        stroke: #bcbcbd;
        stroke-width: 2;
      }

      &.openable-shadow {
        stroke: rgba(0, 0, 0, 0.12);
        stroke-width: 2;
      }
    }

    .title {
      color: $color-text-black;
      font-family: $font-condensed-roboto;
      font-weight: 300;
      font-size: 1rem;
      line-height: 1.2;
      text-anchor: start;
      &.sub {
        text-anchor: end;
      }
    }

    .root-title {
      font-weight: 300;
    }
  }

  @for $i from 1 through length($task-types) {
    $type: nth($task-types, $i);
    .#{$type} {
      fill: getValue($task-types, $task-fills, $type);
    }
  }

  .draggable {
    .body-container {
      cursor: -webkit-grab;
      cursor: grab;

      .shadow {
        &.hover {
          fill: #404040;
          opacity: 0.23;
          transform: translate(3px, 3px);
        }

        &.drag {
          fill: #404040;
          opacity: 0.2;
          transform: translate(5px, 10px);
        }
      }
    }

    &.drag {
      cursor: -webkit-grabbing;
      cursor: grabbing;
    }
  }

  .task {
    &.edit-mode {
      .background {
        stroke: #2d425d;
        stroke-dasharray: 5, 5;
        stroke-linecap: round;
      }
    }

    &.active {
      .body-container {
        .background {
          stroke: #31a0ff;
          stroke-width: 3px;
        }
      }
    }

    &.locked {
      .lock {
        .lock-bg {
          fill: #888;
        }

        text {
          fill: $color-white;
          font-size: 13px;
          text-anchor: middle;
        }
      }
    }

    user-select: none;
    &.disabled {
      cursor: pointer;
    }

    .background {
      fill: $color-white;
    }

    .brush-background {
      fill: $color-black;
      fill-opacity: 0.2;
    }

    .circle-tag {
      cursor: ew-resize;
      fill: #2d425d;
    }

    .time-places {
      rect {
        border-radius: 3px;
        fill: #2d425d;
      }

      text {
        fill: $color-white;
        font-size: 13px;
        text-anchor: middle;
      }
    }

    .content {
      color: $color-text-dark-blue;
      font-family: $font-condensed-roboto;
      font-weight: 300;
      font-size: 14px;
      line-height: 1.29;
      letter-spacing: 0.2px;
      text-anchor: middle;
      &.success {
        fill: #004015;
      }

      &.warning {
        fill: #432a00;
      }

      &.danger {
        fill: #940000;
      }
    }

    .label {
      .indicator {
        fill: transparent;
        @for $i from 1 through length($task-types) {
          $type: nth($task-types, $i);
          &.#{$type} {
            fill: getValue($task-types, $task-fills, $type);
          }
        }
      }
    }

    .decorator {
      fill: #f4f4f4;
      opacity: 0.7;
    }
  }

  .alert-icon {
    cursor: pointer;

    &.alert {
      .circle {
        fill: $color-status-red-tiny;
      }
    }

    &.warning {
      .circle {
        fill: #ff9f00;
      }
    }

    &.disabled-warning {
      .circle {
        fill: $color-white;
        stroke: #909090;
      }

      .sign {
        fill: #909090;
      }
    }

    .sign {
      fill: $color-white;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .menu-item,
  .category {
    .bg {
      fill: $color-timeline-category-bg;
    }

    .btn {
      cursor: pointer;
      &:hover .bg {
        fill: $color-timeline-border;
      }
      &.toggled {
        fill: $color-timeline-border;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      .bg {
        fill: $color-timeline-category-button-bg;
        stroke: $color-timeline-border;
        transition: fill 0.3s ease-out;
      }

      .label {
        text-anchor: middle;
        text-transform: capitalize;
      }

      .material-icons {
        font-size: 20px;
      }
    }
  }

  .date-button {
    cursor: pointer;
    &:hover {
      .button-prev,
      .button-next {
        fill: $color-white;
      }
    }
  }

  .droppable {
    .success {
      fill: #00e04a;
    }

    .error {
      fill: #ff1400;
    }
  }

  .shadow {
    fill: $color-black;
    opacity: 0.3;
    transform: translate(1px, 1px);
  }

  .menu-item.opened {
    .title {
      font-weight: normal;
    }

    .toggle-circle {
      fill: #808080;
      stroke: #808080;
    }

    .toggle-mark {
      fill: $color-white;
      fill-rule: evenodd;
      stroke: $color-white;
      stroke-width: 1;
      transform: translate(-4px, -1.5px);
    }
  }

  .openable-toggle * {
    cursor: pointer;
  }

  .toggle-circle {
    fill: transparent;
    stroke: #c7c7c7;
  }

  .toggle-mark {
    fill: $color-radio-button-dot;
    transform: translate(-1.5px, 4px) rotate(-90deg);
  }

  .holiday {
    fill: transparent;
  }

  .isHoliday .holiday {
    fill: $color-status-red-tiny;
  }

  .cross {
    cursor: pointer;

    circle {
      fill: #2d425d;
    }

    text {
      fill: $color-white;
      font-size: 18px;
    }
  }
}

.loader-wrapper {
  background: $color-background-grey;
  bottom: 0;
  left: 0;
  min-height: 200px;
  position: absolute;
  right: 0;
  top: 0;

  &.hide {
    display: none;
  }

  .loader {
    color: $color-text-black;
    display: block;
    font-size: 24px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -100%);
  }

  .loader:before {
    animation-delay: -0.32s;
    left: -3.5em;
  }

  .loader:after {
    left: 3.5em;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

// TODO fix me
.cutable-container {
  .cutable-line {
    stroke: #2d425d;
    stroke-dasharray: 4, 5;
    stroke-linecap: round;
    stroke-width: 3px;
  }

  text {
    fill: $color-white;
    font-size: 15px;
  }

  circle {
    fill: #2d425d;
  }

  .arrow {
    fill: #2d425d;
  }
}

.dialog-container {
  .cancel-btn,
  .apply-btn {
    cursor: pointer;

    rect {
      fill: #2d425d;
    }

    text {
      alignment-baseline: middle;
      font-size: 20px;
    }
  }

  .apply-btn text {
    fill: #00e04a;
  }

  .cancel-btn text {
    fill: #ff0b0b;
  }
}
