@import "variables";

.ng2-nouislider {
  margin-bottom: 3rem;
  .noUi-target {
    background: $color-background-grey;
    border: solid 1px $idle-border-color;
    border-radius: 6px;
    box-shadow: none;
    transition: background 0.3s ease-in;
    &:hover {
      background: $color-white;
      border-color: $hover-border-color;
      .noUi-handle {
        border-color: $hover-border-color;
        &:before,
        &:after {
          background: #e3e3e3;
        }
      }

      .noUi-tooltip {
        border-color: $hover-border-color !important;
      }
    }
  }

  .noUi-horizontal {
    height: 20px;
    .noUi-handle {
      background: $color-white;
      border: solid 1px $active-border-color;
      border-radius: 20px;
      box-shadow: none;
      cursor: -webkit-grab;
      cursor: -moz-grab;
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
      height: 40px;
      margin-right: 6px;
      top: -10px;
      transition: border 0.3s ease-in;
      width: 12px;
      &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
      }

      &:before,
      &:after {
        left: 3px;
        top: 11px;
        transition: background 0.3s ease-in;
      }

      &:after {
        left: 6px;
      }
    }

    .noUi-origin {
      .noUi-tooltip {
        border-color: $active-border-color;
        display: inline-table;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        padding: 3px 8px;
        text-align: center;
        transform: translate(-50%, 11px);
        transition: border 0.3s ease-in;
        &:after {
          background: $color-white;
          bottom: -2px;
          content: '';
          display: block;
          height: 3px;
          left: calc(50% - 5px);
          position: absolute;
          width: 10px;
        }
      }

      &:first-child .noUi-handle,
      &:last-child .noUi-handle {

      }

      &:first-child .noUi-tooltip {
        border-radius: 6px 0 6px 6px;
        left: auto;
        right: 0;
        transform: translate(1px, 64px);
        &:after {
          left: auto;
          right: 0;
          top: -2px;
        }
      }

      &:last-child .noUi-tooltip {
        border-radius: 0 6px 6px 6px;
        left: 0;
        transform: translate(-1px, 64px);
        &:after {
          left: 0;
          top: -2px;
        }
      }
    }
  }

  .noUi-marker-horizontal {
    &.noUi-marker {
      background: #979797;
      height: 10px;
      top: 10px;
      width: 1px;
    }

    &.noUi-marker-large {
      height: 26px;
      top: 3px;
    }
  }

  .noUi-value {
    color: $active-text-color;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    text-align: center;
    transform: translate3d(-50%, 95%, 0);
  }

  .noUi-connect {
    background: $basic-green-color;
    box-shadow: none;
  }

  .noUi-state-drag {
    &.noUi-target {
      background-image: linear-gradient(to bottom, #ffd0b5, $color-task-coral)
    }

    .noUi-handle {
      border: 1px solid $idle-border-color;
    }

    .noUi-connect {
      background: rgb(215, 215, 215);
    }
  }
}
