@import 'variables';

$checkbox-padding: 30px;

%bordered {
  border-bottom: 1px solid $color-form-field-bordered-border;
  padding-bottom: 5px;
}

.pl-input-clickable {

  &:not(:checked),
  &:checked {
    left: -9999px;
    opacity: 0;
    position: absolute;
  }

  // Checkbox Styles
  // Text Label Style
  ~label {
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -ms-user-select: none;
    /* IE10+ */
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    color: $active-text-color;
    cursor: pointer;

    display: inline-block;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    height: 28px;
    padding-left: $checkbox-padding;
  }

  /* checkbox aspect */
  ~label:before,
  ~label:after {
    border: 1px solid;
    border-radius: 5px;
    content: "";
    height: $basic-size;
    left: 0;
    margin-top: 3px;
    position: absolute;
    top: 0;
    transition: top, left, width, height, border, background, transform 0.15s;
    width: $basic-size;
  }

  ~label:before {
    background: $color-background-grey;
    border-color: $idle-border-color;
    z-index: 0;
  }

  ~label:after {
    border-color: $hover-border-color;
    z-index: 1;
  }

  &:disabled {
    ~label {
      color: $disabled-text-color;
    }

    ~label:before,
    ~label:after {
      border-color: $disabled-border-color;
    }

    ~label:before {
      @include hatch-bg;
    }
  }

  &:not(:checked):not(:disabled)~label:hover:before {
    border-color: $hover-border-color;
  }

  &:not(:checked):not(:disabled)~label:hover:after {
    transform: scale(0.5);
  }

  &:checked {
    ~label:before {
      backface-visibility: hidden;
      background: transparent;
      border-bottom: 2px solid $color-white;
      border-left: 0;
      border-radius: 0;
      border-right: 2px solid $color-white;
      border-top: 0;
      height: 14px;
      left: 1px;
      top: 2px;
      transform: rotate(40deg);
      transform-origin: 100% 100%;
      width: 7px;
      z-index: 1;
    }

    ~label:after {
      background: $basic-green-color;
      border: 0;
      transform: scale(1);
      z-index: 0;
    }

    &:disabled~label:after {
      background: #b3b3b3;
    }
  }

  &.right {
    ~label {
      min-width: 58px; // fixme: hack for tables
      padding-left: 0;
      padding-right: $checkbox-padding - 5px;
      text-align: right;
    }

    ~label:before,
    ~label:after {
      left: auto;
      right: 0;
    }

    //~ label:after {
    //  right: 11px;
    //}

    &:checked {
      ~label:before {
        right: 12px;
      }

      ~label:after {
        right: 1px;
      }
    }
  }

  // --------- Radio ----------
  &[type="radio"] {
    ~label {
      position: relative;
    }

    ~label:before,
    ~label:after {
      border-radius: 50%;
    }
  }

  &[type="radio"]:checked {
    ~label:before {
      background: $basic-green-color;
      border: 1px solid #6fdb7f;
      border-radius: 50%;
      height: 12px;
      left: 4px;
      top: 4px;
      transform: rotate(0deg);
      width: 12px;
    }

    ~label:after {
      background: $color-white;
      border: 1px solid $basic-green-color;
      left: 0;
    }

    &:disabled {
      ~label {
        color: $disabled-text-color;
      }

      ~label:before,
      ~label:after {
        background: transparent;
        border-color: $disabled-border-color;
      }
    }
  }

  &.form-radio-button[type="radio"] {
    ~label::before {
      background: $color-white;
    }

    &:checked {
      ~label::before {
        background: $color-radio-button-dot;
        border-color: $color-radio-button-border;
      }

      ~label::after {
        border-color: $color-radio-button-border;
      }
    }
  }

  // --------- Switches ----------
  &.switch {
    label .lever {
      background: rgba(255, 255, 255, 0.55);
      border: solid 1px $idle-border-color;
      border-radius: $rounded-border-radius;
      height: 16px;
      width: 45px;

      &:after {
        background: $color-background-grey;
        border: solid 1px $idle-border-color;
        border-radius: $rounded-border-radius;
        box-shadow: none;
        height: 24px;
        left: -2px;
        top: -5px;
        width: 24px;
      }

      &:before {
        background-color: $color-white;
        border: solid 1px $hover-border-color;
        border-radius: $rounded-border-radius;
        content: "";
        display: inline-block;
        height: 14px;
        left: 3px;
        position: absolute;
        top: 0;
        transform: scale(0);
        transition: left 0.3s ease, transform 0.3s ease;
        width: 14px;
        z-index: 1;
      }
    }

    label input[type="checkbox"]:not(:checked):not(:disabled)~.lever:not(:active):hover {
      background-color: rgba(255, 255, 255, 0.55);
      border: solid 1px $hover-border-color;

      &:after {
        border: solid 1px $hover-border-color;
      }

      &:before {
        transform: scale(1);
      }
    }

    label input[type="checkbox"]:checked~.lever {
      background-color: $basic-green-color;
      border: solid 1px $basic-green-color;

      &:after {
        background-color: $color-white;
        border: solid 1px #2dd144;
      }

      &:before {
        background-color: $basic-green-color;
        border: solid 1px $basic-green-color;
        left: 29px;
        transform: scale(1);
      }
    }

    input[type="checkbox"]:not(:disabled)~.lever:active,
    input[type="checkbox"]:not(:disabled).tabbed:focus~.lever {
      background-image: linear-gradient(to bottom, #95f3b0, $basic-green-color);

      &:after {
        background: $color-white;
        box-shadow: none;
      }

      &:before {
        transform: scale(0);
      }
    }

    label input[type="checkbox"]:disabled~.lever {
      border: solid 1px $disabled-border-color;
      @include hatch-bg;

      &:after {
        background: $color-background-grey;
        border: solid 1px $disabled-border-color;
      }
    }
  }

  &.colors {
    label {
      color: $color-black;
      display: block;
      font-weight: 100;
      font-size: 16px;
      height: $color-box-size;
      letter-spacing: 0.9px;
      line-height: 31px;
      padding-left: $color-box-size + 18px;
      transition: none;

      &:after,
      &:before {
        display: none;
      }

      span {
        height: $color-box-size;
        left: 0;
        position: absolute;
        top: 0;
        width: $color-box-size;

        &:after {
          backface-visibility: hidden;
          background: transparent;
          border-bottom: 2px solid $color-white;
          border-left: 0;
          border-radius: 0;
          border-right: 2px solid $color-white;
          border-top: 0;
          content: "";
          display: none;
          height: 14px;
          left: 7px;
          position: absolute;
          top: 7px;
          transform: rotate(40deg);
          transform-origin: 100% 100%;
          width: 7px;
          z-index: 1;
        }
      }
    }

    &[type="radio"]:checked~label {
      font-weight: normal;

      span:after {
        display: block;
      }
    }
  }
}

// ================================= Forms ==================
.pl-form {

  &:not(.editable) .pl-input,
  &:not(.editable) .pl-validation,
  &.editable .pl-view:not(.static) {
    display: none;
  }
}

.pl-input,
.pl-label,
.pl-validation,
.pl-view {
  box-sizing: border-box;
  display: block;

  &.inline {
    display: inline-block;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}

.pl-input,
.pl-view {
  color: $color-black;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;
  margin: 10px 0 0;

  // FIXME Cratch for presentation
  pre {
    color: $color-black;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    margin: 10px 0 0;
  }
}

.pl-input-box {
  margin-bottom: 10px;
  position: relative;

  &.bordered {
    @extend %bordered;
  }

  &.dashed {
    border-bottom-style: dashed;
  }

  &.editable {
    .pl-input {
      display: block;
    }

    .pl-view {
      display: none;
    }
  }

  &.inline {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.pl-label {
  color: #5a5b5f;
  font-weight: 300;
  font-size: 13px;
}

.pl-input {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  height: auto;
  outline: none;
  padding: 0;
  transition: none;
  width: 100%;

  &:focus:not([readonly]) {
    border: 0;
    box-shadow: none;
  }

  &:valid~.pl-validation {
    display: none;
  }
}

.pl-validation {
  color: $color-status-red-tiny;
}

.pl-chip {
  @include chips;
  background: $color-white;
}

.datepicker-tools {
  .buttons-group {
    text-align: right;
  }
}

.form-field {
  margin-bottom: 2 * $unit;

  &.tall {
    display: grid;
    min-height: 4 * $unit;

    > :not(label) {
      align-self: end;
    }
  }

  &.bordered {
    @extend %bordered;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.invalid {

  &.bordered,
  .pl-input-clickable~label::after {
    border-color: $color-invalid;
  }
}

.mat-form-field-disabled {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}