@import 'colors';

snack-bar-container.mat-mdc-snack-bar-container {
  font-weight: 300;
  max-width: 75vw;

  .mat-mdc-simple-snackbar-action {
    color: $color-error-button-fg;
  }

  &.break-spaces simple-snack-bar {
    white-space: pre-line;
  }
}