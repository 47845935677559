@import 'variables';

$btn-shadow-enabled: rgba(0, 0, 0, 0.17);
$btn-shadow-disabled: rgba(0, 0, 0, 0.13);
$btn-font-color: #767676;
$btn-color-disabled: #c7c7c7;
$btn-submit-color: #2d425d;
$btn-border-color: #2d425d;
$btn-delete-color: #fd0000;
$btn-cancel-color: $color-white;
$btn-submit-text-color: $color-white;
$blue-hover-color: #0080ff;
$btn-radius: 15px;

.pl-button {
  background: transparent;
  border: 1px solid $idle-border-color;
  border-radius: $rounded-border-radius;
  color: $active-text-color;
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.2;
  outline: none;
  padding: 10px 30px;
  text-align: center;
  transition: background 0.3s ease-in-out;
  vertical-align: middle;
  &:hover {
    background-color: rgba(255, 255, 255, 0.55);
    border: solid 1px $hover-border-color;
  }

  &.active {
    background-color: $color-white;
    border: solid 1px $active-border-color;
  }

  &:active {
    background-color: rgba(175, 175, 175, 0.5);
    border: solid 1px $active-border-color;
  }

  &:disabled,
  &.disabled {
    border: solid 1px $disabled-border-color;
    color: $disabled-text-color;
    opacity: 0.7;
    @include hatch-bg;
  }

  &.thin {
    font-size: 16px;
    padding: 3px 10px;
  }

  &.dotted {
    border-style: dotted;
  }

  &.dashed {
    border-style: dashed;
  }
}

.circle-button {
  @extend %center-content-inline;
  background-color: $color-white;
  border: solid 1px $color-background-grey;
  border-radius: 50%;
  cursor: pointer;
  font-size: 0;
  height: $btn-radius * 2;
  outline: none;
  text-align: center;
  vertical-align: middle;
  width: $btn-radius * 2;

  &:after {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  &:hover {
    background: $blue-hover-color;

    .material-icons {
      color: $color-white;
    }
  }

  .material-icons {
    color: #b4b4b4;
    font-size: 18px;
    vertical-align: middle;
  }
}

button.plugin-btn {
  border: 0;
  width: 100%;
}

.plugin-btn {
  background-color: #fffd41;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.3), 0 0 1px 0 rgba(0, 0, 0, 0.11);
  color: $color-black;
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
  font-size: 16px;
  line-height: 2rem;
  margin-bottom: 6px;
  margin-left: 10px;
  outline: none;
  padding: 0 10px;
  position: relative;
  text-align: center;

  .material-icons {
    font-size: 18px;
    left: 0;
    position: absolute;
    top: 0;
    transform: translate(7px, 0.33em);
  }

  &.settings {
    background-color: $color-white;
    box-shadow: 0 1px 0 0 $btn-shadow-enabled;
    display: block;
    margin: 0 0 10px;
    padding: 0 0 0 25px;
    &[disabled] {
      background-color: $btn-shadow-disabled;
      box-shadow: none;
      color: $btn-font-color;
    }
  }

  &.svg {
    cursor: pointer;
    rect {
      fill: none;
      stroke: #5087a2;
      stroke-dasharray: 4 2;
      stroke-width: 1;
    }

    text {
      fill: $color-black;
      font-weight: 300;
      font-size: 16px;
      text-anchor: middle;
    }

    &.label {
      rect {
        fill: $color-background-grey;
        stroke: none;
      }

      text {
        fill: $color-black;
      }

      &.edit-mode-on {
        rect {
          fill: $color-background-grey-lighter;
        }
      }

      &.color-hol {
        rect {
          fill: $color-cluster-label-holiday;
        }
      }

      &.color-spec {
        rect {
          fill: $color-cluster-label-spec-day;
        }
      }
    }
  }

  &.clear {
    background: transparent;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    box-shadow: none;
    margin-left: 0;
  }

  &.block {
    display: block;
  }
}
