@import "variables";

.ngx-datatable {
  overflow: visible;
  text-align: left;
  z-index: 1;

  &.fixed-row {
    .datatable-scroll .datatable-body-row .datatable-body-cell {
      overflow: visible;
    }
  }

  &.scroll-vertical {
    height: 70vh;
  }

  &.material {
    background: transparent;
    box-shadow: none;

    &.striped .datatable-row-odd {
      background: rgba($color-black, 0.02);
    }

    .datatable-header-cell:not(:last-child),
    .datatable-body-cell:not(:last-child) {
      background-image: linear-gradient(rgba($color-black, 0.2) 33%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-repeat: repeat-y;
      background-size: 1px 8px;
    }

    .datatable-header {
      border-bottom: 1px solid rgba($color-black, 0.2);

      .datatable-header-cell {
        color: $color-black;
        font-weight: 300;
        font-size: 16px;
        line-height: 1;
        font-stretch: normal;
        letter-spacing: 0.9px;
        padding: 12px 18px;

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          text-align: center;
        }

        .datatable-header-cell-label {
          color: $color-black;
          font-weight: 300;
          font-size: 16px;
          letter-spacing: 0.9px;
        }
      }
    }

    .datatable-row-group {
      .datatable-body-cell {
        color: $color-black;
        font-weight: 300;
        font-size: 16px;
        line-height: 1;
        font-stretch: normal;
        letter-spacing: 0.9px;
        padding: 0 18px;
      }

      .datatable-body-cell:first-child,
      .datatable-body-cell:nth-child(3) {
        font-weight: 300;
      }

      .datatable-body-cell-label {
        align-items: center;
        display: flex;
        height: 100%;
      }
    }

    .material-icons {
      color: #b3b3b3;
    }

    .datatable-footer {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      font-size: 16px;

      .page-count {
        height: 40px;
        line-height: 40px;
        padding: 0 1.2rem;
      }

      .datatable-pager {
        margin: 0 10px;
        //line-height: 50px;

        li {
          vertical-align: middle;

          &.disabled a {
            background-color: transparent !important;
            color: rgba(0, 0, 0, 0.26) !important;
          }

          &.active a {
            background-color: rgba(158, 158, 158, 0.2);
            font-weight: bold;
          }
        }

        a {
          border-radius: 3px;
          color: rgba(0, 0, 0, .54);
          height: 22px;
          line-height: 22px;
          margin: 6px 3px;
          min-width: 24px;
          padding: 0 6px;
          text-align: center;
          text-decoration: none;
          vertical-align: top;
          vertical-align: bottom;

          &:hover {
            background-color: rgba(158, 158, 158, 0.2);
            color: rgba(0, 0, 0, .75);
          }
        }
      }
    }
  }

  &.material.editable {
    * {
      color: $color-black;
    }

    .material-icons {
      color: #b3b3b3;
      opacity: 0.9;
    }

    &.striped .datatable-row-odd {
      background: #e1e3e4;
    }

    .datatable-header-cell:not(:last-child),
    .datatable-body-cell:not(:last-child) {
      background-image: linear-gradient(rgba($color-black, 0.29) 33%, rgba(255, 255, 255, 0) 0%);
    }

    .datatable-header {
      border-bottom: 1px solid rgba($color-black, 0.29);
    }

    .datatable-body .datatable-body-row:hover,
    .datatable-body .datatable-body-row:hover .datatable-row-group {
      background-color: rgba($color-white, 0.2);
    }

    .delete-row .material-icons {
      color: #ec001d;
    }
  }

  .pl-input, .pl-view {
    font-size: 16px;
  }

  .pl-input-box.clickable {
    margin-bottom: 0;
    .pl-label {
      color: $color-black;
      font-weight: 100;
      font-size: 16px;
      line-height: 1.5;
    }
  }

  .empty-row {
    font-size: 18px;
    font-weight: 300;
    margin-top: 5px;
    text-align: center;
  }
}

.add-row {
  border: dashed 1px $color-black;
  border-radius: 3px;
  color: $color-black;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.7;
  font-stretch: normal;
  height: 31px;
  letter-spacing: 0.9px;
  margin: 10px 0 0 13px;
  text-align: center;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }

  &:active {
    background: rgba(255, 255, 255, 0.3);
  }
}

.delete-row {
  cursor: pointer;
}
