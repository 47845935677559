@import 'colors';

$color-status-red-transparent: transparentize($color-status-red, 0.7);
$color-status-green-transparent: transparentize($color-status-green, 0.7);
$color-status-blue-transparent: transparentize($color-status-blue, 0.7);

.approved {
  background-color: $color-status-green-transparent;
}
.pending {
  background-color: $color-status-blue-transparent;
}
.declined {
  background-color: $color-status-red-transparent;
}
.unassigned {
  background-color: $color-status-grey;
}
