@import 'variables';

$checkbox-size: 25px;
$tooltip-arrow-width: 20px;
$tooltip-arrow-height: 13px;
$tooltip-arrow-offset: 10px;
$tooltip-radius: 5px;
$tooltip-bevel: 8.5px;

$tooltip-bg: $color-white;
$tooltip-border-color: #bcbcbc;
$tooltip-elements-border-color: #d5d5d5;

$tooltip-min-width: 150px;

$linechartShadow: 2px 2px 1px 0 rgba($color-black, .1);

.pl-tooltip,
pl-tooltip-dynamic {
  background: $tooltip-bg;
  border-radius: $tooltip-radius;
  border: 1px solid $tooltip-border-color;
  color: $color-text-black;
  display: block;
  left: 0;
  line-height: 1.2;
  max-width: 50%;
  min-width: $tooltip-min-width;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity .25s ease-in-out;
  visibility: hidden;
  z-index: 900;

  @media screen and (max-width: $vacay-sm-breakpoint) {
    &.mobile-centered {
      $margin: 0;

      // sass-lint:disable no-important (this would be overwritten inline by javascript unfortunately)
      left: $margin !important;
      // the max-height is due to the pl-tooltip-container that disrupts the positioning of the dynamic content. So its a semi magic number-but necessary for mobile
      max-height: 634px;
      max-width: initial !important;
      min-width: initial !important;
      overflow-y: auto;
      top: 50% !important;
      transform: translate(0, -50%) !important;
      width: calc(100vw - 2 * #{$margin}) !important;
      // sass-lint:enable no-important

      &.open {
        &.left,
        &.left-top,
        &.left-bottom {
          margin-left: 0;
          transform: translateY(-50%);
        }
      }

      .arrow-container {
        display: none;
      }
    }
  }

  &.open {
    opacity: 1;
    transition: opacity 0.1s ease-in;
    visibility: visible;
  }

  &.fixed {
    position: fixed;
  }

  &.linechart-node {
    box-shadow: $linechartShadow;

    .arrow-container {
      height: 15px !important;

      .arrow {
        box-shadow: $linechartShadow;
      }
    }
  }

  &.internal-padding {
    padding: 30px;
  }

  &.employee-overview-tooltip{
    font-size: 14px;
    text-align: center;
    padding-bottom: $unit * 2/3;
    padding-left: $unit * 2/3;
    padding-right: $unit * 2/3;
    padding-top: 0;
    min-width: revert;
  }

  &.navigation-tooltip {
    box-shadow: none;
  }

  &.comment {
    border: $color-icons-gray 2px solid;
    border-radius: 3px;
    box-shadow: 0 14px 40px 15px rgba(0, 0, 0, 0.1);
    display: block;
    transition: none;

    &.top {
      transform: translate(-50%, -100%) scale(0);

      &.open {
        transform: translate(-50%, -100%) scale(1);
        transform-origin: 50% 110%;
      }
    }

    &.top-right {
      transform: translate(-100%, -100%) scale(0);

      &.open {
        transform: translate(-100%, -100%) scale(1);
        transform-origin: 100% 110%;
      }
    }

    &.top-left {
      transform: translateY(-100%) scale(0);

      &.open {
        transform: translateY(-100%) scale(1);
        transform-origin: 0 110%;
      }
    }

    &.bottom {
      transform: translateX(-50%) scale(0);

      &.open {
        transform: translateX(-50%) scale(1);
        transform-origin: 50% -5%;
      }
    }

    &.bottom-right {
      transform: translateX(-100%) scale(0);

      &.open {
        transform: translateX(-100%) scale(1);
        transform-origin: 99% -10%;
      }
    }

    .arrow-container {
      .arrow {
        border: $color-icons-gray 2px solid;
      }
    }
  }

  &.configurations {
    box-shadow: 0 20px 30px 0 rgba(204, 204, 204, 0.8), 1px 2px 1px 0 rgba(0, 0, 0, 0.2) !important;
  }

  .tooltip-header {
    background: $color-background-grey;
    border-bottom: 1px solid darken($color-background-grey, 5%);
    border-radius: $tooltip-radius $tooltip-radius 0 0;
    color: $color-black;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1;
    padding: 10px;
    text-align: center;
  }

  .tooltip-content {
    padding: 5px 0;
  }

  .tooltip-footer {
    border-radius: 0 0 5px 5px;
    border-top: 1px solid darken($tooltip-bg, 5%);
    padding: 10px;
    text-align: center;

    .pl-button {
      background-color: $color-timeline-category-button-bg;
      border: solid 1px $tooltip-elements-border-color;
      border-radius: 3px;
      font-family: $font-condensed-roboto;
      font-size: 1rem;
      height: 36px;
      line-height: 1em;
      padding: 0 24px;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .error-list {
    display: flex;
    flex-direction: column;
    padding: 10px;

    li {
      color: $color-white;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.2;
      text-align: center;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  .arrow-container {
    position: absolute;

    &:not(.full) {
      overflow: hidden;
    }

    .arrow,
    .arrowFull {
      height: $tooltip-arrow-width;
      position: absolute;
      width: $tooltip-arrow-width;
      z-index: 1;
    }

    .arrow {
      background: $tooltip-bg;
      border: 1px solid #bcbcbc;
      transform: rotate(45deg);
    }

    .arrowFull {
      box-sizing: content-box;

      &:before,
      &:after {
        box-sizing: border-box;
      }
    }
  }

  &.top,
  &.top-left,
  &.top-right {
    box-shadow: 0 -2px 7px 0 rgba($color-black, .2);
    margin-top: -$tooltip-arrow-height;

    .arrow-container {
      height: $tooltip-arrow-height;
      top: 100%;
      width: 100%;

      .arrow {
        border-radius: 0 0 3px 0;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      .arrowFull {
        bottom: 0;
        left: 50%;
        padding: 0 $tooltip-arrow-offset;
        transform: translateX(-50%);

        &::before {
          background-image: linear-gradient(to bottom, #309fff, #2d425d);
          bottom: -1px;
          clip-path: polygon(0% 0%, 100% 0%, calc(100% - #{$tooltip-bevel}) 100%, $tooltip-bevel 100%);
          content: '';
          height: 15px;
          left: calc(50% - 1px);
          position: absolute;
          transform: translateX(-50%);
          width: calc(100% + .5px);
        }

        &::after {
          background: $color-task-break;
          border-radius: 3px 3px 0 0;
          bottom: $tooltip-arrow-height;
          content: '';
          height: $tooltip-arrow-width - $tooltip-arrow-height;
          left: calc(50% - .5px);
          position: absolute;
          transform: translateX(-50%);
          width: 100%;
        }
      }
    }
  }

  &.top {
    transform: translate(-50%, -100%);
  }

  &.top-left {
    transform: translateY(-100%);
  }

  &.top-right {
    transform: translate(-100%, -100%);
  }

  &.bottom,
  &.bottom-left,
  &.bottom-right {
    box-shadow: 0 2px 7px 0 rgba($color-black, .2);
    margin-top: $tooltip-arrow-height;

    .arrow-container {
      bottom: 100%;
      height: $tooltip-arrow-height;
      width: 100%;

      .arrow {
        border-radius: 3px 0 0 0;
        left: 50%;
        top: 4px;
        transform: translateX(-50%) rotate(45deg);
      }

      .arrowFull {
        left: 50%;
        padding: 0 $tooltip-arrow-offset;
        transform: translateX(-50%);

        &::before {
          background-image: linear-gradient(to top, #309fff, #2d425d);
          clip-path: polygon($tooltip-bevel 0%, calc(100% - #{$tooltip-bevel}) 0%, 100% 100%, 0% 100%);
          content: '';
          height: 15px;
          left: calc(50% - 1px);
          position: absolute;
          top: -1px;
          transform: translateX(-50%);
          width: calc(100% + .5px);
        }

        &::after {
          background: $color-task-break;
          border-radius: 0 0 3px 3px;
          content: '';
          height: $tooltip-arrow-width - $tooltip-arrow-height;
          left: calc(50% - 0.5px);
          position: absolute;
          top: $tooltip-arrow-height;
          transform: translateX(-50%);
          width: 100%;
        }
      }
    }
  }

  &.bottom {
    transform: translateX(-50%);
  }

  &.bottom-right {
    transform: translateX(-100%);
  }

  &.top-right .arrow-container,
  &.bottom-right .arrow-container {
    right: 0;
  }

  &.right,
  &.right-top,
  &.right-bottom {
    box-shadow: 2px 0 7px 0 rgba($color-black, 0.2);
    margin-left: $tooltip-arrow-height;

    .arrow-container {
      height: 100%;
      right: 100%;
      width: $tooltip-arrow-height;

      .arrow {
        border-radius: 0 0 0 3px;
        left: 4px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }

      .arrowFull {
        padding: $tooltip-arrow-offset 0;
        top: calc(50% - 1px);
        transform: translateY(-50%);

        &::before {
          background-image: linear-gradient(to left, #309fff, #2d425d);
          clip-path: polygon(0 calc(100% - #{$tooltip-bevel}), 0 $tooltip-bevel, 100% 0%, 100% 100%);
          content: '';
          height: 100%;
          left: -1px;
          position: absolute;
          top: 0;
          width: 15px;
        }

        &::after {
          background: $color-task-break;
          border-radius: 0 3px 3px 0;
          content: '';
          height: 100%;
          left: $tooltip-arrow-height;
          position: absolute;
          top: calc(50% - 0.5px);
          transform: translateY(-50%);
          width: $tooltip-arrow-width - $tooltip-arrow-height;
        }
      }
    }
  }

  @media screen and (max-width: $vacay-sm-breakpoint) {
    &.right,
    &.right-top,
    &.right-bottom {
      margin-left: 0;
    }
  }

  &.right {
    transform: translateY(-50%);
  }

  &.right-bottom {
    transform: translateY(-100%);
  }

  &.right-top .arrow-container {
    .arrowFull {
      top: calc(50% - 3px);

      &::after {
        top: 50%;
      }
    }
  }

  &.left,
  &.left-top,
  &.left-bottom {
    box-shadow: -2px 0 7px 0 rgba($color-black, .2);
    margin-left: -$tooltip-arrow-height;

    .arrow-container {
      height: 100%;
      left: 100%;
      width: $tooltip-arrow-height;

      .arrow {
        border-radius: 0 3px 0 0;
        right: 4px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }

      .arrowFull {
        padding: $tooltip-arrow-offset 0;
        right: 0;
        top: calc(50% - 1.5px);
        transform: translateY(-50%);

        &::before {
          background-image: linear-gradient(to right, #309fff, #2d425d);
          clip-path: polygon(0% 100%, 0 0%, 100% $tooltip-bevel, 100% calc(100% - #{$tooltip-bevel}));
          content: '';
          height: 100%;
          position: absolute;
          right: 1px;
          top: 0;
          width: 12px;
        }

        &::after {
          background: $color-task-break;
          border-radius: 3px 0 0 3px;
          content: '';
          height: 100%;
          position: absolute;
          right: $tooltip-arrow-height;
          top: 50%;
          transform: translateY(-50%);
          width: $tooltip-arrow-width - $tooltip-arrow-height;
        }
      }
    }
  }

  @media screen and (max-width: $vacay-sm-breakpoint) {
    &.left,
    &.left-top,
    &.left-bottom {
      margin-left: 0;
    }
  }

  &.left {
    transform: translate(-100%, -50%);
  }

  &.left-top {
    transform: translateX(-100%);

    .arrow-container {
      .arrowFull {
        top: calc(50% - 3px);
      }
    }
  }

  &.left-bottom {
    transform: translate(-100%, -100%);

    .arrow-container {
      .arrowFull {
        top: calc(50% - 1px);
      }
    }
  }

  &.left-bottom .arrow-container,
  &.right-bottom .arrow-container {
    bottom: 0;
  }

  &.top-left .arrow-container,
  &.top-right .arrow-container,
  &.right-top .arrow-container,
  &.right-bottom .arrow-container,
  &.bottom-left .arrow-container,
  &.bottom-right .arrow-container,
  &.left-top .arrow-container,
  &.left-bottom .arrow-container {
    max-width: $tooltip-min-width;
  }

  .confirmation {
    .items {
      padding: 37px 0 33px;
      text-align: center;
    }

    span {
      display: inline-block;
      margin: 2.5px;
      padding: 5px 8px;

      &:not(.divider) {
        background: $color-white;
        border: 1px solid $tooltip-elements-border-color;
        border-radius: 3px;
        color: $color-text-dark-blue;
        font-family: $font-condensed-roboto;
      }

      &.divider {
        background: transparent;
        color: #333;
        font-weight: 300;
        height: 30px;
        padding: 6px 10px;
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
        vertical-align: middle;
      }
    }
  }

  .list:not([hidden]) {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 5px -2.5px;
  }

  .tooltip-shift {
    background: $color-white;
    border-radius: 3px;
    box-shadow: 0 0 0 1px $tooltip-elements-border-color;
    color: $color-text-dark-blue;
    cursor: pointer;
    margin: 2.5px;
    padding: 6px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      background: $color-background-grey-lighter;
    }
  }

  hr {
    border: 0 none;
    border-top: 1px dashed $tooltip-elements-border-color;
    margin: 5px 0;
  }
}

.tooltip-list {
  $left-margin: 10px;
  margin: 0;

  li {
    margin: 0;
    padding: 6px 10px 6px 40px;
    position: relative;
    text-align: left;

    &:not(:first-child) {
      border-top: 1px solid $tooltip-elements-border-color;
    }

    [type='checkbox'] {
      // Text Label Style
      + label {
        color: $color-black;
        display: block;
        font-weight: 300;
        padding-left: 0;
      }

      /* checkbox aspect */
      + label:before,
      + label:after {
        left: $left-margin;
        top: 7px;
      }

      &:checked + label:before {
        left: $left-margin + 1px;
        top: 9px;
      }
    }

    [type='radio'] {
      // Text Label Style
      + label {
        color: $color-black;
        display: block;
        font-weight: 300;
        padding-left: 0;
      }

      /* checkbox aspect */
      + label:before,
      + label:after {
        left: -$left-margin - 20px;
        margin-top: 0;
        top: 4px;
      }

      &:checked + label:before {
        left: -26px;
        top: 8px;
      }

      &:checked + label:after {
        left: -$left-margin - 20px;
        top: 4px;
      }
    }
  }
}

pl-tooltip-dynamic.activities {
  box-shadow: 0 2px 1px 0 rgba(64, 64, 64, .2), 0 0 40px 15px rgba(0, 0, 0, .2);

  &.right,
  &.right-top,
  &.right-bottom {
    .arrowFull:before {
      top: -1px;
    }
  }

  &.supervisor {
    $supervisor-bevel: 6.5px;

    &.top,
    &.top-left,
    &.top-right {
      .arrowFull::before {
        clip-path: polygon(0% 0%, 100% 0%, calc(100% - #{$supervisor-bevel}) 100%, $tooltip-bevel 100%);
      }
    }

    &.bottom,
    &.bottom-left,
    &.bottom-right {
      .arrowFull::before {
        clip-path: polygon($tooltip-bevel 0%, calc(100% - #{$supervisor-bevel}) 0%, 100% 100%, 0% 100%);
      }
    }

    &.right,
    &.right-top,
    &.right-bottom {
      .arrowFull::before {
        clip-path: polygon(0 calc(100% - #{$supervisor-bevel + 1px}), 0 $tooltip-bevel, 100% 0%, 100% 100%);
      }
    }

    &.left,
    &.left-top,
    &.left-bottom {
      .arrowFull::before {
        clip-path: polygon(0% 100%, 0 0%, 100% $tooltip-bevel, 100% calc(100% - #{$supervisor-bevel}));
      }
    }
  }

  .tooltip-content {
    padding: 15px;
  }

  .content-container {
    padding: 0 25px;
  }

  .filter {
    margin-bottom: 10px;

    .pl-input {
      border-bottom: 1px solid #b8b8b8;
      font-weight: 300;
      font-size: 16px;
      height: 2.3rem;
      margin-bottom: 0;
      margin-top: 0;

      &:focus {
        box-shadow: none;
      }
    }
  }

  $colors-list: (
    'green': #08d922,
    'yellow': #ffd300,
    'red': #ff0b0b,
    'grey': #c3c6cd,
  );

  .shifts {
    border-left: 5px solid;
    margin-bottom: 3px;
    padding: 3.5px 25px 3.5px 20px;

    @each $name, $color in $colors-list {
      &.#{$name} {
        background: rgba($color, .5);
        border-left-color: $color;
      }
    }
  }

  .list:not([hidden]) {
    margin: 0 -2.5px;
  }

  .tooltip-shift {
    background: rgba(#fff, 0.6);
    box-shadow: none;
    color: rgba(0, 0, 0, .7);
    font-family: $font-condensed-roboto;
    font-weight: 300;
    font-size: 14px;
    min-height: 25px;
    padding: 3px 6px;

    &:not(.no-action):hover {
      background: rgba(#fff, .9);
    }

    &.no-action {
      background: #ebebeb;
      cursor: auto;
    }

    @each $name, $color in $colors-list {
      &.#{$name} {
        background: $color;
      }
    }
  }

  .tooltip-footer {
    background: $color-background-grey;
    padding-bottom: 15px;

    .content-container {
      padding-left: 30px;
    }

    .list li {
      margin: 0 2.5px;

      > div:first-child {
        color: #272b32;
        font-family: $font-condensed-roboto;
        font-weight: 300;
        font-size: 12px;
      }
    }

    .list.with-shifts li {
      width: 65px;

      .shifts {
        border-radius: 3px;
        height: 15px;
        margin-top: 5px;
      }
    }

    .tooltip-shift {
      margin: 3px 0;
    }

    .title {
      text-transform: uppercase;
    }
  }
}
