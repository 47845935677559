@import 'fonts';

// PLACE 1 => src/assets/fonts/material-icons/stylesheet.css
// PLACE 2 in this file
// PLACE 3 src/app/_shared/tooltips/configuration-tooltip/configuration-tooltip.component.scss
%material-icons {
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    direction: ltr;
    font-family: $font-mat-icons;
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
}

%center-content {
    align-items: center;
    display: flex;
    justify-content: center;
}

%center-content-inline {
    align-items: center;
    display: inline-flex;
    justify-content: center;
}

%box-shadow {
    box-shadow: 0 2px 0 0 $color-wget-box-shadow;
}
