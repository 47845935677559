@import 'variables';
@import 'colors';

mat-dialog-container {
  .mat-mdc-dialog-content {
    line-height: normal;
    font-weight: 300;

    &.break-spaces {
      white-space: pre-line;
    }

    .invalid {
      color: $color-invalid;
    }
  }
}
