$height: 99px;

.gradient-bg {
  background: $grey-grey-900;
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
  height: $height;
  z-index: 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    opacity: 0.3;
    z-index: 0;
  }

  &.full,
  &.full:before {
    height: 100vh;
  }

  &.argus-report,
  &.argus-report:before,
  &.masterplan-create,
  &.masterplan-create:before {
    height: 100vh;
    max-height: calc(600px + 135px);
  }

  &.masterplan-settings,
  &.masterplan-settings:before {
    height: 110px;
  }

  &.agenda,
  &.agenda:before {
    transition: height $modalTransitionTime ease-in $modalTransitionTime;
  }

  &.modal-opened,
  &.modal-opened:before {
    height: 100vh;
    transition-delay: 0s;
  }

  &.argus-report:after,
  &.masterplan-create:after,
  &.masterplan-settings:after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 135px);
    background-image: linear-gradient(to bottom, rgba(240, 240, 240, 0), #f0f0f0);
  }

  &.timer {
    height: 100%;
    &:before {
      height: 100%;
    }
  }
}

.main-container {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  transform: translateY(0);
  transition: transform $modalTransitionTime ease-in $modalTransitionTime;

  &.modal-opened {
    transform: translateY(100vh);
    transition-delay: 0s;
  }
}

.rostr.schedules.overview .gradient-bg {
  height: $height;
}

.agenda.projects .gradient-bg,
.agenda.objectives .gradient-bg,
.rostr.schedules .gradient-bg,
.settings.shifts .gradient-bg,
.settings.skills .gradient-bg,
.settings.groups .gradient-bg,
.argus .gradient-bg,
div[class^='error'] .gradient-bg {
  height: 100vh;
  position: fixed;
}

.entity.group {
  .gradient-bg {
    height: $nav-height-small;
  }
}
