@import 'app.scss';



html, body { height: 100%; }

body { 
    margin: 0; 
    
    // It's enough to have font-family in one place for parent <body> element.
    // But CSS selectors from some Angular Material components with bigger weight then override (indigo-pink.css theme issue)
    // It's also not really best place here, in case if <button> has no font-family from SCSS code, then user agent value is NO FONT at all.
    // So it's much better to use * { font-family } to set global font entirely for all elements on page.
    // and it's done here => src/app/_shared/styles/_common.scss
}
