@import 'variables';
.pl-overview {
  $bottom-height: 119px;
  $panel-padding: 30px;
  $panel-padding-bottom: 20px;
  $remove-color: #bf0000;

  .container-fluid {
    min-height: calc(100vh - 60px);
    padding-bottom: 78px;
  }

  .panel-container {
    height: 340px;
    margin-bottom: 20px;
    position: relative;
  }

  .panel {
    background-color: $color-white;
    border-radius: 6px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
    height: 100%;
    left: 0;
    margin: 0 15px;
    padding: 30px;
    position: absolute;
    top: 0;
    width: calc(100% - #{$panel-padding});
    z-index: 0;
    &:hover {
      height: auto;
      min-height: 100%;
      z-index: 3;
      .cover {
        display: block;
      }
    }
  }

  .heading {
    font-weight: normal;
    font-size: 18px;
    line-height: 1.5;
  }

  .summary {
    font-weight: 300;
    font-size: 16px;
  }

  .cover {
    background-color: rgba(214, 211, 211, 0.54);
    border: dashed 1px $color-black;
    border-radius: 6px;
    bottom: 0;
    display: none;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    &.show {
      display: block;
    }

    .buttons {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .pl-button {
      align-items: center;
      background: $color-white;
      border-color: #a8a8a8;
      display: flex;
      padding: 6px 23px 8px;
      text-decoration: none;
      white-space: nowrap;
      span {
        display: inline-block;
        font-weight: 300;
        font-size: 18px;
      }

      .material-icons {
        font-size: 20px;
        margin-left: 20px;
      }
    }

    .remove {
      background: #ffcbcb;
      border-color: #ff9393;
      color: $remove-color;
      .material-icons {
        color: $remove-color;
        margin-left: 12px;
      }
    }
  }

  .badge {
    background: $color-background-grey;
    border-radius: 17px;
    color: #272b32;
    display: inline-block;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    height: 25px;
    padding: 0 20px;
    position: absolute;
    right: 15px;
    top: 0;
    transform: translateY(-50%);
    z-index: 2;
  }

  .header {
    color: $color-white;
    font-weight: 100;
    font-size: 35px;
    line-height: 41px;
    margin-bottom: 30px;
    text-align: center;
  }

  .nav-container {
    max-width: none;
  }

  .filters {
    align-items: center;
    background-image: linear-gradient(to bottom, rgba(240, 240, 240, 0.69), #f0f0f0);
    bottom: 0;
    display: flex;
    height: 78px;
    justify-content: center;
    left: 0;
    position: fixed;
    width: 100%;

    a.pl-button {
      background-color: $color-white;
      border-color: #d0d0d0;
      color: #272b32;
      font-weight: 300;
      font-size: 16px;
      margin: 0 5px;
    }
  }

  .bottom-content {
    bottom: $panel-padding-bottom;
    position: absolute;
    width: calc(100% - #{$panel-padding * 2});
  }

  a.pl-button.deleteMode {
    padding: 10px 20px 6px;
    position: absolute;
    right: 15px;
    transition: none;
    .material-icons {
      font-size: 22px;
      line-height: 0.9;
    }

    &:hover,
    &.active {
      background: #ffcbcb;
      border-color: #ff9393;
      .material-icons {
        color: $remove-color;
      }
    }
  }

  a.pl-button.updateMode {
    position: absolute;
    right: 100px;
    padding: 10px 20px 6px;
    transition: none;
    .material-icons {
      font-size: 22px;
      line-height: 0.9;
    }
    &:hover,
    &.active {
      background: #ffcbcb;
      border-color: #ff9393;
      .material-icons {
        color: $remove-color;
      }
    }
  }

  .create-new {
    align-items: center;
    border: dashed 1px $color-white;
    border-radius: 6px;
    display: flex;
    height: 340px;
    justify-content: center;
    margin-bottom: 20px;

    a {
      font-weight: 300;
      font-size: 16px;
      text-decoration: none;
    }
  }

  .create-new-btn {
    align-items: center;
    border: dashed 1px $color-white;
    border-radius: 6px;
    color: $color-white;
    display: flex;
    height: 45px;
    justify-content: center;
    padding: 0 40px;

    .material-icons {
      font-size: 22px;
      margin-left: 20px;
      margin-top: -1px;
    }

    &:hover {
      background: $color-white;
      border: 1px solid #a8a8a8;
      color: #272b32;
      text-decoration: none;
    }
  }

  ar-filter-tooltip {
    .pl-input-box.sort {
      text-align: left;
    }
  }

  @media (min-width: 1600px) {
    .col-xlg-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}
