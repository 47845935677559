@import 'variables';
@import 'flexbox';
// sass-lint:disable no-css-comments

/*
  Below styles can be used to create blocks of elements separated by vertical dashed line.
  Each block have to be inside element with "col-*" and "box" classes and all blocks have to be contained inside element with "separated-boxes" class.
  When viewport is below lg bootstrap's breakpoint blocks are stacked one below the other and dashed line is not displayed.
  Following html structure applies below styling:

  <div class="separated-boxes">
    <div class="col-4 box pl-0">
    </div>
    <div class="col-4 box">
    </div>
    <div class="col-4 box pr-0">
    </div>
  </div>
*/

.separated-boxes {
  margin-top: 2 * $unit;

  @media (min-width: map-get($breakpoints,'lg')) {
    > [class*='col-'] {
      &.box {
        border-right: 1px dashed $color-divider;
        height: 100%;
        padding-left: 2 * $unit;
        padding-right: 2 * $unit;

        &:last-child {
          border: 0;
        }
      }
    }
  }
}
