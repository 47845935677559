$color-white: #fff;
$color-black: #000;
$color-gray: #909090;
$color-alto: #d0d0d0;
$color-red: #f00;
$color-guardsman-red: #ca0404;
$color-background-grey: #f0f0f0;
$color-background-grey-lighter: #f3f3f3;
$color-dodge-blue: #309fff;
$color-icons-gray: #020202;
$color-rhino: #2d425d;
$color-scorpion: #5d5d5d;
$color-mercury: #e6e6e6;
$color-alto: #d3d3d3;
$color-mine-shaft: #282828;
$color-dust-gray: #979797;
$color-tundora: #444343;
$color-alabaster: #fafafa;
$color-boulder: #7c7c7c;
$color-silver: #ccc;
$color-ebony: #111024;
$color-emerald: #3ed954;
$color-malachite: #00e04a;
$color-sunglo: #e77070;
$color-snowy-mint: #c5ffc2;
$color-drover: #fdfdb3;
$color-banana-mania: #fce9ae;
$color-cosmos: #ffcece;
$color-dark-blue: #3f51b5;
$color-text-black: $color-ebony;
$color-success: $color-emerald;
$color-error: $color-sunglo;
$color-invalid: $color-guardsman-red;
$color-primary-button: $color-rhino;
$color-primary-button-hover: #0163b4;
$color-primary-button-text: #fcfdfe;
$color-primary-button-disabled-bg: $color-alto;
$color-light-button-active: #e4e4e4;
$color-cancel-button: #fcfdfe;
$color-cancel-button-hover: #e4e4e4;
$color-cancel-button-text: black;
$color-darkbutton-button-top: #9aacb6;
$color-darkbutton-button-bottom: #404447;
$color-darkbutton-button-top-hover: #b7cbd6;
$color-darkbutton-button-bottom-hover: #5c6064;
$color-button-light-text: $color-white;
$color-page-background: $color-white;
$color-radio-button-dot: #828282;
$color-radio-button-border: #a4a4a4;
$color-error-button-fg: $color-guardsman-red;
$color-checkbox-bg: $color-rhino;
$color-form-field-bordered-border: $color-mercury;
$color-header-fg: $color-black;
$color-header-light-fg: $color-white;
$color-timeline-category-bg: #fefefe;
$color-timeline-category-button-bg: #fbfbfb;
$color-timeline-row-alternating: rgba(0, 0, 0, 0.02);
$color-timeline-row-subcategory: rgba(#dcdee2, 0.8);
$color-timeline-border: #d8d8d8;
$color-timeline-text-header: #1b1c3b;
$color-wget-idle-bg: #dcdcdc;
$color-wget-idle-input-label: #898989;
$color-wget-idle-input-border: #eaeaea;
$color-wget-idle-dropdown-disabled: #cdcdcd;

$color-wget-menu-bg: #454545;

$color-wget-edit-bg: $color-dodge-blue;
$color-wget-edit-input-label: #9cd1ff;
$color-wget-edit-input-border: #61b6ff;
$color-wget-idle-background: #f0ebeb;
$color-wget-footer: #ebebec;
$color-wget-edit-icon: #8b8b8b;
$color-wget-header-text: #fefefe;
$color-wget-list-border: #e9e9e9;
$color-wget-hover: #0080ff;

$color-wget-box-shadow: rgba(0, 0, 0, 0.1);
$color-divider: rgba(0, 0, 0, 0.15);
$color-datepicker-text-default: #7a7a7a;
$color-datepicker-bg-default: $color-white;
$color-datepicker-bg-weekend: rgba(224, 224, 224, 0.76);
$color-datepicker-selected: #009aff;
$color-datepicker-selected-border-severity: $color-guardsman-red;
$color-datepicker-severity-1: rgb(255, 255, 0);
$color-datepicker-severity-2: rgb(255, 213, 0);
$color-datepicker-severity-3: rgb(255, 170, 0);
$color-datepicker-severity-4: rgb(255, 128, 0);
$color-datepicker-severity-5: rgb(255, 85, 0);
$color-datepicker-severity-x: rgb(255, 43, 0);
$color-status-green: #84ffad;
$color-status-yellow: #ffe066;
$color-status-yellow-tiny: #fc0;
$color-status-red: #ff8e8e;
$color-status-blue: $color-dodge-blue;
$color-status-red-tiny: #f00;
$color-status-grey: #f1f1f1;
$color-btn-blue: #004b73;
$color-btn-white: $color-white;
$color-cluster-label-holiday: $color-cosmos;
$color-cluster-label-spec-day: #ffeccc;
$blue-hover-color: #0080ff;
$color-text-dark-blue: #050a49;

$color-task-break: #0da1ef;
$color-task-planner: #ffff34;

$color-task-tangerine: #ff8d8b;
$color-task-coral: #f55223;
$color-task-carrot: #ff9230;
$color-task-orange: #fcc419;
$color-task-khaki: #c5a687;
$color-task-green: #04ea77;
$color-task-eastern: #2199a4;
$color-task-turqoise: #4ae4f8;
$color-task-maliblue: #68a4ff;
$color-task-cove: #6785be;
$color-task-plum: #853c7d;
$color-task-lavender: #b27eff;
$color-task-orchid: #dd76d2;
$color-task-blush: #ff86f2;
$color-health-dayoff: $color-snowy-mint;
$color-health-vacation: #9ff;
$color-health-healthy: #dbffd4;
$color-health-ok: #fcf7a9;
$color-health-stressful: #ffe69c;
$color-health-unhealthy: #ffd2cc;

$color-yellow-tooltip: #fffcbd;
$color-wish-comment-input-border: $color-dust-gray;
$color-wish-comment-input-save-diabled: #c7c7c7;
$color-wish-menu-yellow: #f8e71c;
$color-wish-menu-green: $color-malachite;
$color-wish-menu-red: #ff0c0c;
$color-wish-menu-gray: $color-alto;
$color-wish-menu-blue: #004b75;
$color-wish-menu-white: #fefffe;
$color-edit-button-tooltip: $color-rhino;
$color-item-separator-tooltip: $color-dust-gray;
$color-label-tooltip: #272b32;
$rostr-green: $color-malachite;
$rostr-required-shift: #fff3f3;
$rostr-required-shifts-summary: #ffd9d9;
$rostr-required-shifts-summary-no-items: #dcdee2;
$rostr-employee-index: #f6a639;
$rostr-employee-index-odd: #f5f5f5;
$rostr-employee-index-even: $color-white;
$rostr-employee-badge-agenda: #d2c4ff;
$rostr-employee-badge-vacay: #a1ceff;
$rostr-employee-badge-vacay-completed: #02ff00;
$rostr-cell-even: $color-background-grey-lighter;
$rostr-cell-odd: $color-background-grey;
$rostr-weekend-odd: #dddddd;
$rostr-weekend-even: $color-mercury;
$rostr-hours-odd: $color-alabaster;
$rostr-hours-even: $color-white;
$rostr-details-cell-odd: $color-mercury;
$rostr-details-cell-even: $color-alto;
$rostr-details-weekend-odd: #cccccc;
$rostr-details-weekend-even: #aaaaaa;
$rostr-details-hours-odd: #eeeeee;
$rostr-details-hours-even: #dedede;
$rostr-success: #8ed488;
$rostr-warn-general: #e9ca76;
$rostr-warn-masterplan: #e9bd76;
$rostr-warn-vacay: #e9d476;
$rostr-error: #e97676;
$rostr-primary-color: #e2d5ff;
$rostr-shift-border-primary: #dfdfdf;
$rostr-shift-border-secondary: #b3b3b3;
$rostr-alert: #ff4949;
$rostr-comment-read: #49a4d7;
$rostr-comment-unread: #f45f63;
$rostr-highlight: rgba(
  $color: #3f94cc,
  $alpha: 0.2
);
$rostr-hover: rgba(
  $color: #3f94cc,
  $alpha: 0.3
);
$disabled-border-color: #d9d9d9;
$idle-border-color: $color-alto;
$hover-border-color: #afafaf;
$active-border-color: $color-alto;
$disabled-text-color: $color-gray;
$active-text-color: $color-black;
$basic-green-color: #2cdf24;
$select-chips-fg: $color-ebony;
$select-chips-bg: $color-background-grey;
$select-list-item-fg: $color-ebony;
$color-tooltip-header-fg: $color-white;
$color-tooltip-header-bg: $color-rhino;
$color-tooltip-content-fg: $color-mine-shaft;
$color-tooltip-content-item-separator: $color-dust-gray;
$circle-button-fg: $color-white;
$color-table-text: $color-black;
$color-table-even-row: $color-alabaster;
$table-icon: $color-boulder;
$color-table-border: $color-silver;
$color-badge-fg: $color-tundora;
$color-badge-bg: $color-alto;
$color-spinner-default: $color-mercury;
$color-edit-employee-button: $color-rhino;
$color-alert-icon-employee-tooltip: $color-rhino;
$color-vacay-schedule-background: $color-white;
$color-vacay-navigation-tab-background: rgba($color-white, 0.4);
$color-vacay-health-healthy: $color-snowy-mint;
$color-vacay-health-ok: $color-drover;
$color-vacay-health-stressful: $color-banana-mania;
$color-vacay-health-unhealthy: $color-cosmos;
$color-vacay-border-light: $color-alto;
$color-vacay-border-dark: $color-gray;
$color-vacay-wish: $color-malachite;
$color-vacay-will: $color-dodge-blue;
$color-vacay-holiday-indicator: $color-red;
$color-vacay-comment-indicator: $color-dodge-blue;
$color-vacay-underdelivery-indicator: $color-red;

// Masterplan
$color-masterplan-m-shift: #FAEABB;
$color-masterplan-d-shift: #EBDBB0;
$color-masterplan-e-shift: #DBCEA4;
$color-masterplan-n-shift: #CCBF99;
$color-masterplan-off-shift: #D1EEDD;

// new Horizon pallette
$grey-75: #EBEFF1;
$white: #FFFFFF;
$attention-alarmdark: #851800;
$attention-alarmlight: #FFEDEB;
$attention-alarmprimary: #E02900;
$attention-attentiondark: #004578;
$attention-attentionlight: #EBF6FF;
$attention-attentionprimary: #097CBE;
$attention-successdark: #196539;
$attention-successlight: #EFFBF4;
$attention-successprimary: #2A9D5A;
$attention-warningdark: #553E00;
$attention-warninglight: #FFF9DB;
$attention-warningprimary: #FFBB00;
$blue-blue-100: #ACDAFC;
$blue-blue-200: #73BEEF;
$blue-blue-300: #399EDB;
$blue-blue-400: #097CBE;
$blue-blue-50: #EBF6FF;
$blue-blue-500: #0062A1;
$blue-blue-600: #004578;
$button-day-dark: #960571;
$button-day-light: #D546B0;
$button-daydefault: #B5208F;
$button-duskdark: #42B8B2;
$button-duskdefault: #72D5D0;
$button-dusklight: #91E3DF;
$effect-overlay: #161e2880;
$green-green-100: #A8E8C2;
$green-green-200: #65D493;
$green-green-300: #36C571;
$green-green-400: #2A9D5A;
$green-green-50: #EFFBF4;
$green-green-500: #21834A;
$green-green-600: #196538;
$grey-grey-100: #D7DEE4;
$grey-grey-200: #B1BCC8;
$grey-grey-300: #8393A5;
$grey-grey-400: #6B7E94;
$grey-grey-50: #F6F7F9;
$grey-grey-500: #5C6C7F;
$grey-grey-600: #3B4959;
$grey-grey-700: #2A3541;
$grey-grey-800: #1C2530;
$grey-grey-900: #121921;
$mint-mint-100: #F1FDFD;
$mint-mint-200: #91E3DF;
$mint-mint-300: #72D5D0;
$mint-mint-400: #42B8B2;
$mint-mint-500: #22958F;
$mint-mint-600: #0C6762;
$red-red-100: #FDBEB3;
$red-red-200: #FC725A;
$red-red-300: #FB4B24;
$red-red-400: #E02900;
$red-red-50: #FFEDEB;
$red-red-500: #B12000;
$red-red-600: #851800;
$red-red-75: #ffd9d9;
$tangerine-tangerine-100: #FFECE1;
$tangerine-tangerine-200: #FFD2BB;
$tangerine-tangerine-300: #FEBA97;
$tangerine-tangerine-400: #FAA275;
$tangerine-tangerine-500: #E3701C;
$tangerine-tangerine-600: #D15700;
$tangerine-tangerine-700: #853700;
$text-default: #242B34;
$text-disabled: #C0C6CE;
$text-link: #B5208F;
$text-muted: #00000091;
$violetdark-violet-100: #E6B4D9;
$violetdark-violet-200: #CB7CB7;
$violetdark-violet-300: #AA4C93;
$violetdark-violet-400: #84216B;
$violetdark-violet-50: #FCF3F9;
$violetdark-violet-500: #6C0C54;
$violetdark-violet-600: #4C0039;
$violetlight-violet-100: #FAB4E6;
$violetlight-violet-200: #EC78CD;
$violetlight-violet-300: #D546B0;
$violetlight-violet-400: #B5208F;
$violetlight-violet-50: #FFF0FB;
$violetlight-violet-500: #960571;
$violetlight-violet-600: #6A004D;
$yellow-yellow-100: #FFE499;
$yellow-yellow-200: #FFD666;
$yellow-yellow-300: #FFC933;
$yellow-yellow-400: #FFBB00;
$yellow-yellow-50: #FFF9DB;
$yellow-yellow-500: #AA7D00;