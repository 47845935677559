@import 'colors';

mat-checkbox {

  &.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-mdc-accent,
  &.mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-mdc-accent {
    .mat-mdc-ripple-element {
      background: $color-checkbox-bg;
    }
  }

  &.mat-mdc-checkbox-checked.mat-mdc-accent .mat-mdc-checkbox-background,
  &.mat-mdc-checkbox-indeterminate.mat-mdc-accent .mat-mdc-checkbox-background {
    background-color: $color-checkbox-bg;
  }

  &.full-width {
    display: flex;
    margin-top: 15px;
    width: 100%;

    .mat-mdc-checkbox-layout {
      justify-content: space-between;
      width: 100%;
    }

    .mat-mdc-checkbox-inner-container {
      margin-right: 12px;
    }

    .mdc-form-field.mdc-form-field--align-end {
      display: flex;
      width: 100%;

      label {
        flex-grow: 2;
        margin-right: 0 !important;
      }
    }
  }
}