// Define Material UI breakpoint names

$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

$spacer: 1rem;

$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3
);

$padding: 15px;

// Mixin to generate column classes for a breakpoint

@mixin create-columns($breakpoint, $columns) {
  @media (min-width: map-get($breakpoints, $breakpoint)) {
    @for $i from 1 through $columns {
      .col-#{$breakpoint}-#{$i} {
        padding-left: $padding;
        padding-right: $padding;
        flex: 0 0 (100% / $columns) * $i;
        max-width: (100% / $columns) * $i;
      }
    }
  }
  @for $i from 1 through $columns {
    .col-#{$breakpoint}-#{$i} {
      position: relative;
      width: 100%;
      padding-left: $padding;
      padding-right: $padding;
    }
  }
}

@mixin create-default-columns($columns) {
  @for $i from 1 through $columns {
    .col-#{$i} {
      padding-left: $padding;
      padding-right: $padding;
      flex: 0 0 (100% / $columns) * $i;
      max-width: (100% / $columns) * $i;
    }
  }
}

.col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

// Provide basic `.col-{bp}` classes for equal-width flexbox columns

@mixin create-columns-with-equal-width($breakpoint) {
  @media (min-width: map-get($breakpoints, $breakpoint)) {
    .col-#{$breakpoint} {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      padding-left: $padding;
      padding-right: $padding;
    }
  }
}

// Mixin to generate container classes for a breakpoint

@mixin container-styles($padding-x) {
  // Default styles for `.container` class
  .container {
    width: 100%;
    padding-right: $padding-x;
    padding-left: $padding-x;
    margin-right: auto;
    margin-left: auto;
    max-width: none;
  }
  // Default styles for `.container-fluid` class
  .container-fluid {
    width: 100%;
    padding-right: $padding-x;
    padding-left: $padding-x;
    margin-right: auto;
    margin-left: auto;
  }

  // Breakpoint-specific styles for `.container` class
  @each $breakpoint, $container-max-width in $container-max-widths {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      .container {
        max-width: $container-max-width;
      }
    }
  }
}

// Container and row styles

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$padding;
  margin-right: -$padding;

  &.no-gutters {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

// Alignment classes
.text-center {
  text-align: center;
}

.flex-column {
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}
.justify-content-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.align-start {
  align-items: flex-start;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.align-baseline {
  align-items: baseline;
}
.align-stretch {
  align-items: stretch;
}

// Overwriting margins

@mixin bootstrap-margin {
  @for $i from 0 through 5 {
    $m: map-get($spacers, $i);
    .m-#{$i} {
      margin: $m !important;
    }
    .mt-#{$i} {
      margin-top: $m !important;
    }
    .mb-#{$i} {
      margin-bottom: $m !important;
    }
    .ml-#{$i} {
      margin-left: $m !important;
    }
    .mr-#{$i} {
      margin-right: $m !important;
    }
    .mx-#{$i} {
      margin-left: $m !important;
      margin-right: $m !important;
    }
    .my-#{$i} {
      margin-top: $m !important;
      margin-bottom: $m !important;
    }
  }

  /* Margin auto utilities */

  .m-auto {
    margin: auto !important;
  }
  .mt-auto {
    margin-top: auto !important;
  }
  .mb-auto {
    margin-bottom: auto !important;
  }
  .ml-auto {
    margin-left: auto !important;
  }
  .mr-auto {
    margin-right: auto !important;
  }
  .mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

// Overwriting paddings

@mixin bootstrap-padding {
  @for $i from 0 through 5 {
    $p: map-get($spacers, $i);
    .p-#{$i} {
      padding: $p !important;
    }
    .pt-#{$i} {
      padding-top: $p !important;
    }
    .pb-#{$i} {
      padding-bottom: $p !important;
    }
    .pl-#{$i} {
      padding-left: $p !important;
    }
    .pr-#{$i} {
      padding-right: $p !important;
    }
    .px-#{$i} {
      padding-left: $p !important;
      padding-right: $p !important;
    }
    .py-#{$i} {
      padding-top: $p !important;
      padding-bottom: $p !important;
    }
  }
}

// Overwriting displays

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }
}

// Create default columns

@include create-default-columns(12);

// Generate column classes for each breakpoint

@each $breakpoint, $value in $breakpoints {
  @include create-columns($breakpoint, 12);
}

@include container-styles($padding);

@each $breakpoint, $value in $breakpoints {
  @include create-columns-with-equal-width($breakpoint);
}

@include bootstrap-margin;

@include bootstrap-padding;
