@import "variables";

.nav-divider {
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, .18);
  margin: 0;
}

.nav-container {
  align-items: center;
  display: flex;
  height: 50px;
  margin: 0 auto;
  max-width: 1210px;
  width: 90%;

  &.analysis {
    max-width: none;
    padding: 0 20px;
    width: 100%;
  }

  .nav-item {
    flex: 1;
    text-align: center;

    &,
    a {
      color: $color-white;
      font-weight: 300;
      font-size: 16px;
    }

    a {
      cursor: pointer;
      text-decoration: none;

      &:hover span {
        border-bottom-color: $color-white;
      }

      span {
        border-bottom: 1px solid transparent;
        display: inline-block;
        font-size: 16px;
        line-height: 23px;
        vertical-align: middle;
      }

      .material-icons {
        border-bottom: 0;
        font-size: 21px;
        margin-bottom: 1px;
        vertical-align: middle;
      }
    }

    &:first-child {
      text-align: left;

      .material-icons {
        margin-right: 5px;
      }
    }

    &:last-child {
      text-align: right;

      .material-icons {
        margin-left: 5px;
        margin-top: -1px;
      }
    }
  }
}
