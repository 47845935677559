@import 'variables';
@import 'typography';

@at-root {
  @-ms-viewport {
    width: device-width;
  }
}

html {
  -webkit-text-size-adjust: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 200;
  font-size: $font-rem;
}

* {
  font-family: $font-roboto;
}

body {
  position: relative;
  overflow-x: hidden;
  background: $color-background-grey;
  &.modal-opened {
    min-width: 100vh;
    overflow: hidden;
  }
}

.a-right {
  text-align: right;
}

.a-left {
  text-align: left;
}

.a-center {
  text-align: center;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                         not supported by any browser */
}

.common-container {
  padding: 10px 35px;
}

.no-events {
  pointer-events: none;
}

.truncate-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: -o-ellipsis-lastline;
  @for $i from 1 through 4 {
    &.line-#{$i} {
      -webkit-line-clamp: $i;
      height: 1.3em * $i;
    }
  }
}

.truncate-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.highlight {
  font-weight: normal;
}

.clickable {
  cursor: pointer;
}

.placeholder-block {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 90%;
  max-width: 1210px;
  height: 250px;
  margin: 0 auto 40px;
  border: 1px dotted $color-white;
  border-radius: 6px;

  .text-bg {
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.15);
    color: #272b32;
    height: 45px;
    text-decoration: none;
    border-radius: 6px;

    .text {
      font-weight: normal;
      font-size: 16px;
      text-decoration: none;
      color: $color-white;
    }
  }
}

.no-margin {
  margin: 0 !important;
}

.hidden {
  // sass-lint:disable-block no-important
  display: none !important;
}

.faded-out {
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s 1s,
    opacity 1s linear;
}
